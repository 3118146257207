import { Box, Drawer, styled } from '@mzc-pdc/ui';
import { theme } from '../../theme';

export const DRAWER_WIDTH = 400;
const DRAWER_HEADER_HEIGHT = 64;

export const DetailViewDrawerHeader = styled(Box)({
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  padding: `12px`,
  height: DRAWER_HEADER_HEIGHT,
  borderBottom: `1px solid ${theme.palette.divider}`
});
export const DetailViewDrawerBody = styled(Box)({
  display: `flex`,
  flexDirection: `column`,
  padding: `20px`,
  gap: `20px`
});

export const DetailViewDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
    top: theme.mixins.toolbar.minHeight,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight})`
  }
});
