import {
  Box as PdcBox,
  Step as PdcStep,
  StepContent as PdcStepContent,
  Stepper as PdcStepper,
  StepLabel as PdcStepLabel
} from '@mzc-pdc/ui';

import { styled, PDCAlpha, theme } from '../../../theme';

export const Box = styled(PdcBox)`
  position: relative;
`;

export const Stepper = styled(PdcStepper)`
  &.Mui-active {
    outline: 1px solid red;
  }

  .MuiStepConnector-vertical {
    display: none;
  }
`;

export const StepLabel = styled(PdcStepLabel)`
    padding: 12px 20px;
    background-color: ${theme.palette.grey['50']};
    border-radius: 5px;

    &.Mui-disabled {
        background-color: ${theme.palette.grey['50']};
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -35px;
        top: 48px;
        width: 0;
        height: 100%;
        border-left: 3px dotted ${theme.palette.divider};
    }

    &.Mui-active {
        &:before {
            border-color: ${theme.palette.primary.main};
        }
    }

    &.Mui-completed {
        &:before {
            border-color: ${theme.palette.success.main};
        }
    }

    .MuiStepLabel-iconContainer {
        position: absolute;
        left: -47px;
    }

    .MuiStepIcon-root {
        width: 28px;
        height: 28px;
        color: ${theme.palette.common.white};
        border: 1px solid ${theme.palette.divider};
        border-radius: 100%;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            background-color: red;
        }

        .MuiStepIcon-text {
            fill: ${theme.palette.text.secondary};
        }

        &.Mui-active,
        &.Mui-completed {
            border: none;

            .MuiStepIcon-text {
                fill: ${theme.palette.common.white};
            }
        }

        &.Mui-active {
            color: ${theme.palette.primary.main};
        }

        &.Mui-completed {
            color: ${theme.palette.success.main};
        }

        .MuiStepIcon-text {
            font-size: 12px;
        }
    }
`;
export const Step = styled(PdcStep)`
    position: relative;
    margin-left: 50px;

    margin-top: 10px;
    border: 1px solid ${theme.palette.divider};
    border-radius: 5px;

    > .MuiBox-root {
    }

    &:last-of-type {
        .MuiStepLabel-root {
            &:before {
                display: none;
            }
        }
    }
`;

export const StepContent = styled(PdcStepContent)`
  margin: 0;
  padding: 0;
  border: 0;

  .MuiCollapse-entered {
    border-top: 1px solid ${theme.palette.divider};
  }
`;

export const StepSummary = styled('div')`
  padding: 12px 20px;
  background-color: ${({ theme }) => PDCAlpha(theme.palette.secondary[50], 0.24)};
  border-top: 1px solid ${theme.palette.divider}
`;

export const StepFooter = styled(`div`)`
  padding: 15px 30px;
  border-top: 1px solid ${theme.palette.divider};
`;

export const Summary = styled(`div`)`
  .sprite {
    transform: scale(0.73);

    + span {
      margin-left: 4px;
    }
  }

  .MuiDivider-root {
    margin: 0 5px 0 8px;
    height: 14px;
    align-self: center;

    &:first-of-type {
      display: none;
    }
  }
`;
