export const createQueryKey = (keys, params) => {
  if (params != null) {
    const parameters = Object.entries(params).map(([key, value]) => {
      if (key != null && value != null) {
        if (Array.isArray(value)) {
          return `${key}=${value.map((v) => v.toString())}`;
        }
        return `${key}=${value.toString()}`;
      } else return '';
    });

    if (parameters.length > 0) {
      for (const parameter of parameters) {
        keys.push(parameter);
      }
    }
  }
  return keys;
};
