import React, { useCallback, useState } from 'react';
import CompareJobV4DetailModal from '@/components/myJob/modals/CompareJobV4DetailModal';

const useCompareJobV4DetailModal = ({ spaceId }) => {
  const [isOpen, setOpen] = useState(false);
  const [job, setJob] = useState({});

  return {
    show: useCallback(({ job }) => {
      setJob({ ...job });
      setOpen(true);
    }, []),
    Modal: useCallback(
      () =>
        isOpen ? (
          <CompareJobV4DetailModal spaceId={spaceId} job={job} onClose={() => setOpen(false)} />
        ) : null,
      [isOpen, spaceId, job]
    )
  };
};

export default useCompareJobV4DetailModal;
