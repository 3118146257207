import { useModal } from '@/hooks';
import React from 'react';
import UploadDetailModal from '@/components/myJob/modals/UploadDetailModal';
import { useTranslation } from 'react-i18next';

const useUploadDetailModal = () => {
  const {
    Modal,
    show,
    setModalClass,
    setModalTitle,
    setModalBody,
    setShowClose,
    setCloseButtonText
  } = useModal();
  const { t } = useTranslation('common');
  const showUploadDetailModal = ({ targetObject }) => {
    setModalClass('modal-panel-detail');
    setModalTitle(t('label.detail'));
    setCloseButtonText(t('label.close'));
    setShowClose(true);
    setModalBody(() => <UploadDetailModal targetObject={targetObject} />);
    show();
  };
  return {
    Modal,
    show: showUploadDetailModal
  };
};

export default useUploadDetailModal;
