import React from 'react';
import { StepperController } from './StepperController';

interface StepperContextType {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  controller: StepperController;
  childrenCount: number;
}

export const StepperContext = React.createContext<StepperContextType | undefined>(undefined);
