import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Button, Dialog } from '@packages/legacy-ui';

export const useModal = () => {
  const [isShowing, setShowing] = useState(false);
  const [style, setStyle] = useState({ display: 'block' });
  const [modalClass, setModalClass] = useState('');
  const [dialogClass, setDialogClass] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalBody, setModalBody] = useState(null);
  const [closeButtonText, setCloseButtonText] = useState('close');
  const [submitButtonText, setSubmitButtonText] = useState('submit');
  const [submitButtonClass, setSubmitButtonClass] = useState('btn-primary');
  const [submitButtonColor, setSubmitButtonColor] = useState('primary');
  const [isShowClose, setShowClose] = useState(false);
  const [isShowSubmit, setShowSubmit] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [buttonHandlers, setButtonHandlers] = useState({
    onSubmit: null,
    onClose: null
  });

  const run = (fn, ...rest) => _.isFunction(fn) && (() => fn(...rest))();
  const show = useCallback(() => {
    setShowing(true);
    setStyle({ display: 'block' });
    document.documentElement.classList.add('modal-open');
  }, [isShowing, setShowing, style, setStyle]);

  const hide = useCallback(() => {
    setShowing(false);
    setStyle({ display: 'none' });
    document.documentElement.classList.remove('modal-open');
  }, [isShowing, setShowing, style, setStyle]);

  const handleClose = useCallback(
    _.curry((hide, onClose, e) => {
      e.preventDefault();
      run(onClose, e);
      hide();
    })(hide),
    [hide, buttonHandlers.onClose]
  );

  const handleSubmit = useCallback(
    _.curry((hide, onSubmit, e) => {
      e.preventDefault();
      run(onSubmit, e);
      hide();
    })(hide),
    [hide, buttonHandlers.onSubmit]
  );

  // const getModalClassNames = useCallback(
  //   (isShowing, modalClass) =>
  //     classNames(
  //       'modal fade',
  //       _.assign(
  //         { active: isShowing },
  //         _.pipe(_.isNotEmpty, (is) => (is ? { [modalClass]: true } : {}))(modalClass)
  //       )
  //     ),
  //   [isShowing, setShowing, modalClass, setModalClass]
  // );

  // const getDialogClassNames = useCallback(
  //   (dialogClass) =>
  //     classNames(
  //       'modal-dialog',
  //       _.pipe(_.isNotEmpty, (is) => (is ? { [dialogClass]: true } : {}))(dialogClass)
  //     ),
  //   [dialogClass, setDialogClass]
  // );

  const getSubmitButtonClassNames = useCallback(
    (submitButtonClass) =>
      classNames(
        'btn',
        _.pipe(_.isNotEmpty, (is) => (is ? { [submitButtonClass]: true } : {}))(submitButtonClass)
      ),
    [submitButtonClass, setSubmitButtonText]
  );

  const Modal = useCallback(
    () =>
      isShowing
      && ReactDOM.createPortal(
        <Dialog
          type={modalType}
          open={isShowing}
          width={760}
          modalTitle={modalTitle}
          actions={
            <>
              {isShowClose && (
                <Button
                  variant={'text'}
                  color={'inherit'}
                  size={'medium'}
                  onClick={handleClose(_.get('onClose', buttonHandlers))}
                >
                  <span>{closeButtonText}</span>
                </Button>
              )}
              {isShowSubmit && (
                <Button
                  variant={'contained'}
                  size={'medium'}
                  color={submitButtonColor}
                  className={getSubmitButtonClassNames(submitButtonClass)}
                  disabled={submitDisabled}
                  onClick={handleSubmit(_.get('onSubmit', buttonHandlers))}
                >
                  <strong>{submitButtonText}</strong>
                </Button>
              )}
            </>
          }
          // onClose={!isShowing}
          // onClose={handleClose(_.get('onClose', buttonHandlers))}
        >
          {modalBody}
        </Dialog>,
        document.body
      ),
    [
      isShowing,
      setShowing,
      modalClass,
      setModalClass,
      modalTitle,
      handleClose,
      closeButtonText,
      setCloseButtonText,
      setSubmitButtonText,
      isShowing,
      setShowSubmit,
      submitButtonClass,
      setSubmitButtonColor,
      dialogClass
    ]
  );

  return {
    Modal,
    show,
    setModalClass,
    setModalType,
    setDialogClass,
    setModalTitle,
    setModalBody,
    setCloseButtonText,
    setSubmitButtonText,
    setSubmitButtonClass,
    setSubmitButtonColor,
    setDisabledSubmit: setSubmitDisabled,
    setShowSubmit,
    setShowClose,
    setButtonHandlers
  };
};
