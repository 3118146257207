import React from 'react';
import {useNavigate} from 'react-router-dom';
import menuService from "@/services/menuService";
import {useQueryToGetMyInfo} from "@/hooks/queries/useQueryToGetMyInfo";

const WithRouteGuard = ({children}) => {
  const navigate = useNavigate();
  useQueryToGetMyInfo({
    onError: () => {
      navigate(menuService.paths.welcome());
    }
  });

  return (
    <>
      {children}
    </>
  );
};

export default WithRouteGuard;
