const permissions = {
  project: {
    list: ['project:list'],
    detail: ['project:get'],
    action: {
      create: ['project:create'],
      update: ['project:update'],
      delete: ['project:delete']
    }
  },
  projectUser: {
    list: ['project/user:list'],
    detail: ['project/user:get'],
    action: {
      set: ['project/user:set'],
      delete: ['project/user:delete']
    }
  },
  endpoint: {
    detail: ['project/endpoint:get'],
    action: {
      create: ['project/endpoint:create'],
      update: ['project/endpoint:update'],
      delete: ['project/endpoint:delete']
    }
  },
  dns: {
    detail: ['project/dns:get'],
    action: {
      create: ['project/dns:create'],
      update: ['project/dns:update'],
      delete: ['project/dns:delete']
    }
  },
  cdn: {
    list: ['project/cdn:list'],
    detail: ['project/cdn:get'],
    action: {
      create: ['project/cdn:create'],
      update: ['project/cdn:update'],
      delete: ['project/cdn:delete']
    }
  },
  routingPolicy: {
    list: ['project/routing-policy:list'],
    detail: ['project/routing-policy:get'],
    action: {
      create: ['project/routing-policy:create'],
      update: ['project/routing-policy:update'],
      delete: ['project/routing-policy:delete']
    }
  },
  storage: {
    detail: ['project/storage:get'],
    action: {
      create: ['project/storage:create'],
      update: ['project/storage:update'],
      delete: ['project/storage:delete']
    }
  },
  purgeJob: {
    list: ['project/purge-job:list'],
    detail: ['project/purge-job:get'],
    action: {
      create: ['project/purge-job:create']
    }
  },
  compareJob: {
    list: ['project/compare-job:list'],
    detail: ['project/compare-job:get'],
    action: {
      create: ['project/compare-job:create']
    }
  },
  recordJob: {
    list: ['project/record-job:list'],
    detail: ['project/record-job:get'],
    action: {
      create: ['project/record-job:create']
    }
  },
  notification: {
    list: ['project/notification:list'],
    detail: ['project/notification:get'],
    action: {
      create: ['project/notification:create'],
      update: ['project/notification:update'],
      delete: ['project/notification:delete']
    }
  },
  originStorage: {
    awsProxy: ['project/aws:proxy'],
    action: {
      purge: ['project/purge-job:create'],
      compare: ['project/compare-job:create']
    }
  },
  cdnMetrics: {
    list: ['project/cdn-metric:list']
  }
};

const permissionService = {
  permissions: permissions,
  permit: (currentPermissions, permissions) => {
    // currentPermissions = ['project/dns:get'];
    // currentPermissions = ['project/*'];
    // currentPermissions = ['*'];
    // currentPermissions = ['project/*:get'];
    // currentPermissions = ['project/*:list'];
    for (let currentPermission of currentPermissions) {
      const splitPermission = currentPermission.split(':'); // ['project/*:get'] --> ['project/*', 'get']
      // 첫번째 요소의 마지막 글자가 asterisk 인 경우
      if (splitPermission[0][splitPermission[0].length - 1] === '*') {
        if (
          permissions.some(
            (permission) =>
              permission.startsWith(splitPermission[0].slice(0, -1)) // 가장 마지막 문자를 제외한 나머지 문자열
              && (splitPermission[1] == null || permission.endsWith(splitPermission[1])) // 예시) get을 명시하거나, 명시하지 않고 모든 권한이 있는 경우
          )
        ) {
          return true;
        }
      } else {
        if (permissions.includes(currentPermission)) {
          return true;
        }
      }
    }
    return false;
  }
};

export default permissionService;
