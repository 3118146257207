import React from 'react';
import { RadioItem } from './RadioItem';
import { RadioGroup } from '@mzc-pdc/ui';

interface RadioContainerProps<T> {
  value: T;
  onChange: (value: T) => void;
  disabled?: boolean;
  isLoading?: boolean;
  children: React.ReactElement<typeof RadioItem<T>>[] | React.ReactElement<typeof RadioItem<T>>;
}

export const RadioContainer = <T,>(props: RadioContainerProps<T>) => (
  <RadioGroup row value={props.value} onChange={(_event, value) => props.onChange(value as T)}>
    {React.Children.map(props.children, (child: React.ReactElement) =>
      React.cloneElement(child, { disabled: props.disabled || props.isLoading })
    )}
  </RadioGroup>
);
