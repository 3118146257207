import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from '@/App';
import rootReducer from '@/store';

const store = createStore(rootReducer, applyMiddleware(thunk));

const Root = () => (
  <Provider store={store}>
    <Router basename={'/'}>
      <App />
    </Router>
  </Provider>
);

export default Root;
