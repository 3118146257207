import React from 'react';
import {StyledStatusText} from "@/components/common/statuses/status.styled";
import {IconCompleteCircle, IconErrorCircle} from "@mzc-cloudplex/icons";
import theme from "@/styles/theme";
import {Typography} from "@mui/material";
import {CircleProgress} from "@packages/legacy-ui";
import {useTranslation} from "react-i18next";


export const TaskStatus = ({status}) => {
  const { t } = useTranslation('jobs');
  return(
    <>
      {status === 'PENDING' && (
        <StyledStatusText>
          <CircleProgress size={12} color={'inherit'} thickness={5}/>
          <Typography color={theme.palette.grayscaleSecondary[300]}>{t(`pending`)}</Typography>
        </StyledStatusText>
      )}
      {status === 'COMPLETED' && (
        <StyledStatusText>
          <IconCompleteCircle size={12} color={'success'}/>
          <Typography color={theme.palette.success.main}>{t(`completed`)}</Typography>
        </StyledStatusText>
      )}
      {status === 'FAILED' && (
        <StyledStatusText>
          <IconErrorCircle size={12} color={'error'}/>
          <Typography color={theme.palette.error.main}>{t(`failed`)}</Typography>
        </StyledStatusText>
      )}
      {status === 'IN_PROGRESS' && (
        <StyledStatusText>
          <CircleProgress size={12} color={'primary'} thickness={5}/>
          <Typography color={theme.palette.primary.main}>{t(`inProgress`)}</Typography>
        </StyledStatusText>
      )}
    </>
  );
};

export default TaskStatus;
