import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { DEFAULT_LANGUAGE as defaultLanguage } from '@/constants';
import locales from '@/locales';

const resources = {
  en: locales['en-us'],
  ko: locales['ko-kr']
};

const initI18n = async () => {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: defaultLanguage,
      lowerCaseLng: true,
      detection: {
        order: [
          'querystring',
          'cookie',
          'localStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain'
        ],
        lookupQuerystring: 'lang',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLang',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'],
        cookieMinutes: 10,
        cookieDomain: 'myDomain',
        htmlTag: document.documentElement,
        checkWhitelist: true
      },
      defaultNS: 'common',
      nsSeparator: '::',
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    });
};

export default initI18n;
