import { useCallback, useMemo } from 'react';

interface USLocale {
  name: 'English';
  locale: 'en-us';
  value: 'en';
  label: 'english';
}

interface KRLocale {
  name: '한국어';
  locale: 'ko-kr';
  value: 'ko';
  label: 'korean';
}

export type SupportLocaleType = USLocale | KRLocale;

export const useLocaleProvider = () => {
  const locales: Array<SupportLocaleType> = useMemo(
    () => [
      { name: 'English', locale: 'en-us', value: 'en', label: 'english' },
      { name: '한국어', locale: 'ko-kr', value: 'ko', label: 'korean' }
    ],
    []
  );

  return {
    locales: locales,
    get: useCallback((value: string) => {
      const language = value.split('-')[0];
      return locales.find((locale) => locale.value === language);
    }, [])
  };
};
