import moment from 'moment/moment';

export const activityService = {
  createViewModel: (model) => {
    const createdInfo = model?.activities?.find((activity) => activity.action === 'CREATE');
    const updatedInfo = model?.activities?.find((activity) => activity.action === 'LAST_UPDATE');
    return {
      creator: {
        ...model?.creator,
        name: model?.creator?.name ?? '',
        username: model?.creator?.username ?? ''
      },
      createdAt:
        createdInfo?.occurredAt == null
          ? ''
          : moment.utc(createdInfo.occurredAt).local().format('YYYY-MM-DD HH:mm:ss'),
      updater: {
        ...updatedInfo?.actor,
        name: updatedInfo?.actor?.name ?? '',
        username: updatedInfo?.actor?.username ?? '',
        isExisted: () => updatedInfo?.actor != null
      },
      updatedAt:
        updatedInfo?.occurredAt == null
          ? ''
          : moment.utc(updatedInfo.occurredAt).local().format('YYYY-MM-DD HH:mm:ss')
    };
  }
};
