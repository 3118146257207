import { handleActions } from 'redux-actions';
import { UPLOAD_STATUS } from '@/utils/upload';

const UPLOAD_FILE = 'job/UPLOAD_FILE';
const UPDATE_UPLOADING_FILE = 'job/UPDATE_UPLOADING_FILE';
const RETRY_TO_UPLOAD_FILE = 'job/RETRY_TO_UPLOAD_FILE';
const UPDATE_PURGE_DIRTY_FLAG = 'job/UPDATE_PURGE_DIRTY_FLAG';
const UPDATE_COMPARE_DIRTY_FLAG = 'job/UPDATE_COMPARE_DIRTY_FLAG';

const initialState = {
  pending: false,
  isLoaded: false,
  actionType: '',
  uploadFiles: [],
  purgeDirtyFlag: null,
  compareDirtyFlag: null
};
// dirty flag is 상태가 달라졌음 나타내는 플래그
export default handleActions(
  {
    [UPLOAD_FILE]: (state, { type, payload }) => {
      let fileList = Array.from(state.uploadFiles);
      fileList.push(payload.file);

      return {
        ...state,
        pending: true,
        loaded: false,
        actionType: type,
        uploadFiles: fileList
      };
    },

    [UPDATE_UPLOADING_FILE]: (state, { type, payload }) => {
      let fileList = Array.from(state.uploadFiles);
      let list = fileList.map((item) => {
        if (item.status === UPLOAD_STATUS.PREPARED && item.name === payload.file.name) {
          return {
            ...item,
            ...payload.file
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        actionType: type,
        uploadFiles: list
      };
    },

    [RETRY_TO_UPLOAD_FILE]: (state, { type, payload }) => {
      let fileList = Array.from(state.uploadFiles);
      let list = fileList.map((item) => {
        if (
          !(
            item.status === UPLOAD_STATUS.ERROR
            && item.name === payload.file.name
            && item.path === payload.file.path
            && item.size === payload.file.size
          )
        ) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        actionType: type,
        uploadFiles: list.filter((item) => item)
      };
    },

    [UPDATE_PURGE_DIRTY_FLAG]: (state, { type }) => ({
      ...state,
      actionType: type,
      purgeDirtyFlag: Date.now()
    }),

    [UPDATE_COMPARE_DIRTY_FLAG]: (state, { type }) => ({
      ...state,
      actionType: type,
      compareDirtyFlag: Date.now()
    })
  },
  initialState
);

export const uploadFile = (file) => (dispatch) => {
  dispatch({
    type: UPLOAD_FILE,
    payload: {
      file
    }
  });
};

export const updateUploadingFile = (file) => (dispatch) => {
  dispatch({
    type: UPDATE_UPLOADING_FILE,
    payload: {
      file
    }
  });
};

export const retryToUploadFile = (file) => (dispatch) => {
  dispatch({
    type: RETRY_TO_UPLOAD_FILE,
    payload: {
      file
    }
  });
};

export const updatePurgeDirtyFlag = () => (dispatch) => {
  dispatch({
    type: UPDATE_PURGE_DIRTY_FLAG
  });
};

export const updateCompareDirtyFlag = () => (dispatch) => {
  dispatch({
    type: UPDATE_COMPARE_DIRTY_FLAG
  });
};
