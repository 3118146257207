import {
  overrideTheme,
  PDCCreateTheme as createTheme,
  PDCThemeOptions as ThemeOptions,
  ButtonTheme,
  deepmerge,
  overrideComponents
} from '@mzc-pdc/ui';

//project theme 주입
export const projectTheme: ThemeOptions = {
  ...overrideTheme, // pdc/ui에 있는 theme
  palette: {
    ...overrideTheme.palette, // pdc/ui에 있는 컬러 + project에서 입힌 컬러
    primary: {
      A700: '',
      A400: '',
      A200: '',
      A100: '',
      900: '#0011c0',
      800: '#0022d9',
      700: '#002fe3',
      600: '#003bf0',
      500: '#0144fc',
      400: '#2763EA',
      300: '#6280ff',
      200: '#96a4fe',
      100: '#c2c8fe',
      50: '#e7e9ff',
      main: '#2763EA',
      light: '#6280ff',
      dark: '#0011c0',
      contrastText: '#ffffff'
    },
    secondary: {
      A700: '#172967',
      A400: '#334ca5',
      A200: '#7085d0',
      A100: '#c9d4f4',
      900: '#04060F',
      800: '#11193f',
      700: '#1B2856',
      600: '#2A396D',
      500: '#304075',
      400: '#4D5A87',
      300: '#6B769A',
      200: '#939BB7',
      100: '#BDC2D4',
      50: '#E5E7ED',
      main: '#11193f',
      light: '#939BB7',
      dark: '#04060F',
      contrastText: '#ffffff'
    },
    greySecondary: {
      A700: '#33525E',
      A400: '#668896',
      A200: '#A2B9C3',
      A100: '#C9D4F4',
      900: '#2B2B31',
      800: '#3c3d4f',
      700: '#5b5b6f',
      600: '#6f6f83',
      500: '#83839A',
      400: '#9898ad',
      300: '#B6B6CA',
      200: '#D0D0DF',
      100: '#DFDFEE',
      50: '#EFEFF6',
      main: '#3c3d4f',
      light: '#B6B6CA',
      dark: '#2B2B31',
      contrastText: '#ffffff'
    }
  },
  mixins: {
    toolbar: {
      minHeight: `60px`
    }
  }
};

export const projectOverrideTheme: ThemeOptions = {
  ...projectTheme, // pdc/ui에 있는 theme
  breakpoints: {
    keys: [`xs`, `sm`, `md`, `lg`, `xl`, `xxl`],
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1024,
      xl: 1280,
      xxl: 1440
    }
  },
  components: {
    ...overrideComponents(projectTheme),
    MuiButton: {
      ...ButtonTheme(projectTheme),
      styleOverrides: {
        ...ButtonTheme(projectTheme)?.styleOverrides,
        root: {}
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: `48px`
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: () => ({
        html: {
          height: `100%`,
          overflow: `hidden`
        },
        body: {
          height: `100%`,
          wordBreak: `keep-all`
        },
        '&::-webkit-scrollbar': {
          width: `9px`,
          height: `9px`,
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#dce1e5',
          borderRadius: `8px`,
          backgroundClip: `padding-box`,
          WebkitBackgroundClip: `padding-box`,
          border: `2px solid transparent`,
          marginRight: `2px`,
          ':hover': {
            backgroundColor: `#b1b5ba`,
            backgroundClip: `unset`,
            WebkitBackgroundClip: `unset`,
          },
          ':active': {
            backgroundColor: `#878d94`,
          },
        },
        '#root': {
          height: `100%`
        },
        a: {
          color: `inherit`,
          textDecoration: `none`,
          '&:hover': {
            textDecoration: `underline`
          }
        }
      })
    }
  }
};

// Create a theme instance.
const themeA = createTheme(overrideTheme);
const themeB = createTheme(projectOverrideTheme);
const theme = createTheme(deepmerge(themeA, themeB)); // themeA와 themeB를 합친다. other than deepmerge
export default theme;
