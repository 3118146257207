import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'promise-polyfill/src/polyfill';
import 'abortcontroller-polyfill';

import _ from '@/utils/lodash';
import $axios from '@/utils/axios';
import initI18n from '@/utils/i18n';
import { ApiFactory } from './apiFactory';

ApiFactory.init();
await initI18n();

export default (() => ({
  _,
  $axios,
  initI18n
}))();
