import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForListPurgeJobFileObjects = (
  { spaceId, projectId, jobId },
  page,
  size,
  statuses
) => [
  `/spaces/${spaceId}/projects/${projectId}/purge-jobs/${jobId}`,
  `/spaces/${spaceId}/projects/${projectId}/purge-jobs/${jobId}/file-objects`,
  page,
  size,
  statuses
];
export const useQueryToListPurgeJobFileObjects = (
  { spaceId, projectId, jobId },
  page,
  size,
  statuses,
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListPurgeJobFileObjects({ spaceId, projectId, jobId }, page, size, statuses),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/purge-jobs/${jobId}/file-objects`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          params: {
            page: page,
            size: size,
            statuses: statuses
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
