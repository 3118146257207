import config from '@/config';
import axios from 'axios';
import qs from 'qs';
import cookies from 'js-cookie';
import { OAUTH_TOKEN_STORED_KEY } from '@/constants';

const defaultParamSerializer = (params) =>
  // null값 제거, {a:[1,2]} => 'a=1,2'
  qs.stringify(params, {
    skipNulls: true,
    arrayFormat: 'comma',
    encode: true
  });

export const ApiFactory = class {
  static apiInstancesDic = {};
  static validApis = ['configuration', 'cpd3', 'cpd4', 'channelV1', 'adminV1'];

  static getApiInstance = (apiInstance, apiName) => {
    const isValidApiName = (apiName) => ApiFactory.validApis.includes(apiName);
    const mutateInstance = (apiInstance) => {
      const token = cookies.get(OAUTH_TOKEN_STORED_KEY);
      if (token == null) return apiInstance;
      apiInstance.defaults.headers.common['Authorization'] = token;
      return apiInstance;
    };

    if (isValidApiName(apiName)) {
      if (ApiFactory.isExist(apiName)) {
        const existedInstance = ApiFactory.apiInstancesDic[apiName];

        return mutateInstance(existedInstance);
      } else {
        ApiFactory.apiInstancesDic[apiName] = apiInstance;
        return mutateInstance(ApiFactory.apiInstancesDic[apiName]);
      }
    }
  };

  static of = (axiosConfig, apiName) =>
    ApiFactory.getApiInstance(axios.create(axiosConfig), apiName);

  static isExist = (apiName) => ApiFactory.apiInstancesDic[apiName] != null;

  static get configuration() {
    return ApiFactory.getApiInstance(ApiFactory.apiInstancesDic['configuration'], 'configuration');
  }

  static get cpdV3() {
    return ApiFactory.getApiInstance(ApiFactory.apiInstancesDic['cpd3'], 'cpd3');
  }

  static get cpdV4() {
    return ApiFactory.getApiInstance(ApiFactory.apiInstancesDic['cpd4'], 'cpd4');
  }

  static get cpdChannelV1() {
    return ApiFactory.getApiInstance(ApiFactory.apiInstancesDic['channelV1'], 'channelV1');
  }

  static get adminV1() {
    return ApiFactory.getApiInstance(ApiFactory.apiInstancesDic['adminV1'], 'adminV1');
  }

  static init() {
    ApiFactory.of(
      {
        baseURL: config.cpd.configuration,
        paramsSerializer: defaultParamSerializer
      },
      'configuration'
    );

    ApiFactory.of(
      {
        baseURL: config.cpd.apiV3,
        paramsSerializer: defaultParamSerializer
      },
      'cpd3'
    );

    ApiFactory.of(
      {
        baseURL: config.cpd.apiV4,
        paramsSerializer: defaultParamSerializer
      },
      'cpd4'
    );

    ApiFactory.of(
      {
        baseURL: config.cpd.channelV1,
        paramsSerializer: defaultParamSerializer
      },
      'channelV1'
    );

    ApiFactory.of(
      {
        baseURL: config.cpd.adminV1,
        paramsSerializer: defaultParamSerializer
      },
      'adminV1'
    );
  }
};
