import { Box, Typography } from '@mzc-pdc/ui';
import React from 'react';
import { IconOpenInNewSmall } from '@mzc-cloudplex/icons';
import { Link, LinkProps } from '../links/Link';

export const WithId = (props: { id: string; children: React.ReactNode } & LinkProps) => (
  <Box display={'flex'} flexDirection={'column'} flexWrap={'nowrap'}>
    <Link to={props.to} target={props.target}>
      <Box display={'inline-flex'} alignItems={'center'} gap={0.75}>
        {props.children}
        {props.target === '_blank' && <IconOpenInNewSmall size={16} />}
      </Box>
    </Link>
    <Box display={'flex'} flexDirection={'row'}>
      <Link to={props.to} target={props.target}>
        <Typography variant={'body3'} color={'text.secondary'}>
          {props.id}
        </Typography>
      </Link>
    </Box>
  </Box>
);
