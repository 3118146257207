import React, { createContext, useState } from 'react';

const InfoContext = createContext({
  informationForPage: null,
  setInformationForPage: () => {}
});

const InfoContextProvider = (props) => {
  const [informationForPage, setInformationForPage] = useState(null);
  return (
    <InfoContext.Provider
      {...props}
      value={{
        informationForPage: informationForPage,
        setInformationForPage: setInformationForPage
      }}
    />
  );
};

const Info = {
  context: InfoContext,
  Provider: InfoContextProvider
};

export default Info;
