const qa = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.qa.cloudplex.so',
  cpd: {
    configuration: 'https://z0szrboz6g.execute-api.ap-northeast-2.amazonaws.com/qa',
    apiV3: 'https://sj5lwzcb6k.execute-api.ap-northeast-2.amazonaws.com/qa/v3',
    apiV4: 'https://sj5lwzcb6k.execute-api.ap-northeast-2.amazonaws.com/qa/v4',
    channelV1: 'https://channel.qa.cloudplex.me/v1',
    adminV1: 'https://s6snybb9fg.execute-api.ap-northeast-2.amazonaws.com/qa/v1'
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: 'IDKKWFTpmefstobJaJXHbm5mBOMYAW',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default qa;
