import { useMutation } from 'react-query';
import { cancelable } from '@/services/infrastructure/cancelable';
import { exceptionProcessorForMutation } from '@/services/infrastructure/exceptionProcessorForMutation';
import { ApiFactory } from '@/utils/apiFactory';
import { updatePurgeDirtyFlag } from '@/store/job';
import { useDispatch } from 'react-redux';
import tryCatch from '@/services/infrastructure/tryCatch';

export const useMutationToPurge = (
  options = null,
  exceptionHandler = exceptionProcessorForMutation()
) => {
  const dispatch = useDispatch();
  return useMutation(
    ({ spaceId, projectId, cdns, filePaths }) =>
      cancelable(async (signal) =>
        tryCatch(async () => {
          const response = await ApiFactory.cpdV3({
            method: 'post',
            url: `/purge-jobs`,
            headers: {
              'cloudplex-space-id': spaceId,
              'cloudplex-project-id': projectId
            },
            data: {
              name: filePaths[0],
              cdns: cdns,
              filePaths: filePaths
            },
            signal: signal
          });

          dispatch(updatePurgeDirtyFlag());

          return response.data;
        }, exceptionHandler)
      ),
    { ...options }
  );
};
