import { useCallback, useState } from 'react';
import {
  createKeyForListCompareJobFileObjects,
  useQueryToListCompareJobFileObjects
} from '@/hooks/queries/useQueryToListCompareJobFileObjects';
import { useQueryClient } from 'react-query';

const useCompareJobFileObjectList = ({ spaceId, projectId, jobId }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statuses, setStatuses] = useState([]);

  const queryClient = useQueryClient();
  const { data: dataToListCompareJobFileObjects, isLoading: isLoadingToListCompareJobFileObjects } =
    useQueryToListCompareJobFileObjects(
      { spaceId: spaceId, projectId: projectId, jobId: jobId },
      page - 1,
      pageSize,
      statuses
    );

  const refresh = useCallback(async () => {
    await queryClient.invalidateQueries([
      createKeyForListCompareJobFileObjects(
        {
          spaceId: spaceId,
          projectId: projectId,
          jobId: jobId
        },
        page,
        pageSize,
        statuses
      )[0]
    ]);
  }, [spaceId, jobId]);

  return {
    isLoading: isLoadingToListCompareJobFileObjects,
    totalCount: dataToListCompareJobFileObjects?.totalCount ?? 0,
    fileObjects: dataToListCompareJobFileObjects?.results ?? [],
    page,
    pageSize,
    setPage,
    setPageSize,
    statuses,
    setStatuses,
    refresh
  };
};

export default useCompareJobFileObjectList;
