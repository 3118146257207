import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { createKeyForGetCompareJobTask } from '@/hooks/queries/useQueryToGetCompareJobTaskV4';
import { JOB_EVENTS } from '@/hooks/useEventObserver';
import {
  createKeyForGetCompareJob,
  useQueryToGetCompareJobV4
} from '@/hooks/queries/useQueryToGetCompareJobV4';
import { createKeyForListCompareJobTasks } from '@/hooks/queries/useQueryToListCompareJobTasks';

const EVENT_V4_SPEC = '20240823';
// API V4 Compare Job Detail
const useCompareJobDetail = ({ spaceId, projectId, jobId, eventObserver }) => {
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingToGetCompareJob,
    data: dataToGetCompareJob,
    error: errorToGetCompareJob
  } = useQueryToGetCompareJobV4({
    spaceId: spaceId,
    projectId: projectId,
    jobId: jobId
  });

  const isForbidden = errorToGetCompareJob?.response?.status === 403;
  const isUnauthorized = errorToGetCompareJob?.response?.status === 401;

  const isLoading = isLoadingToGetCompareJob;

  const { subscribe, unsubscribe } = useContext(eventObserver.context);

  useEffect(() => {
    const callback = async (messageBody) => {
      if (messageBody.spec !== EVENT_V4_SPEC) return;
      if (messageBody.job.id === jobId) {
        await queryClient.invalidateQueries(
          createKeyForGetCompareJob({
            spaceId,
            projectId,
            jobId
          })
        );
      }

      if (messageBody?.tasks == null && messageBody?.status != null) {
        await queryClient.invalidateQueries(
          createKeyForListCompareJobTasks({ spaceId, projectId, jobId })
        );
      }

      if (messageBody?.tasks != null) {
        for (const task of messageBody.tasks) {
          await queryClient.invalidateQueries(
            createKeyForGetCompareJobTask({ spaceId, projectId, jobId, taskId: task.id })
          );
        }
      }
    };
    subscribe(JOB_EVENTS.COMPARE_JOB, callback);
    return () => unsubscribe(JOB_EVENTS.COMPARE_JOB, callback);
  }, [spaceId, projectId, jobId]);

  return {
    dataToGetCompareJob,
    isLoading,
    isForbidden,
    isUnauthorized
  };
};
export default useCompareJobDetail;
