import getByteLength from './getByteLength';

const validateRequired = (value, rule) => {
  if (rule === true && (!value || value.trim().length === 0)) return { type: 'required' };
  return null;
};

const validatePattern = (value, rule) => {
  if (!new RegExp(rule).test(value)) {
    return {
      type: 'pattern',
      level: 'error',
      message: 'invalidField'
    };
  }
  return null;
};

const validateMaxByte = (value, rule) => {
  if (rule && rule > -1 && getByteLength(value, rule) > rule) {
    return {
      type: 'maxByte',
      level: 'error',
      message: `maxByteLimit`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateUniqueIn = (value, rule) => {
  if (rule.length > 0 && rule.filter((v) => v && v === value).length >= 1) {
    return {
      type: 'uniqueIn',
      level: 'error',
      message: 'Duplicate values. This field must be unique.'
    };
  }

  return null;
};

const validateUrl = (value, rule) => {
  if (
    rule === true
    && !/^([0-9a-z!"#$%&'()*+,\-/:;<=>?@[\\\]^_`{|}]+\.)+([a-z]{2,})$/.test(value)
  ) {
    return {
      type: 'url',
      level: 'error',
      message: 'invalidUrl'
    };
  }
  return null;
};

const validateFullUrl = (value, rule) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' // validate protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
      + '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator

  if (rule === true && !urlPattern.test(value)) {
    return {
      type: 'fullUrl',
      level: 'error',
      message: 'invalidUrl'
    };
  }
  return null;
};

const validateUrlPrefix = (value, rule) => {
  if (rule === true && !/^([a-z0-9][a-z0-9-_]*\.)*[a-z0-9]*[a-z0-9-_]*[[a-z0-9]+$/g.test(value)) {
    return {
      type: 'url',
      level: 'error',
      message: 'invalidDomain'
    };
  }
  return null;
};

/** value의 마지막이 slash char '/'로 끝나는지 확인 합니다. */
const validateOriginPath = (value, rule) => {
  if (rule === true && !_.isEmpty(value) && !/^.*.\/$/gi.test(value)) {
    return {
      type: 'IS_VALID_ORIGIN_PATH',
      level: 'error',
      message: 'invalidOriginPath'
    };
  }

  return null;
};

const validateSame = (value, rule) => {
  if (rule && !_.isEmpty(value) && value !== rule) {
    return {
      type: 'same',
      level: 'error',
      message: `project name is incorrect.` // ToDO: 이거는 범용적인 메시지로 변경 해야함
    };
  }

  return null;
};

const validateDifferent = (value, rule) => {
  if (rule && !_.isEmpty(value) && value === rule) {
    return {
      type: 'different',
      level: 'error',
      message: `isNotDifferent`
    };
  }

  return null;
};

const validateFolder = (value, rule) => {
  if (rule && !_.isEmpty(value) && value.includes('*')) {
    return {
      type: 'folder',
      level: 'error',
      message: `invalidFolderName`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateFilePath = (value, rule) => {
  //if (rule && !_.isEmpty(value) && !/^([^\x80-\xff\\{}"^|%`\]>[~<#*])+$/gi.test(value)) {
  if (
    rule
    && !_.isEmpty(value)
    && (value.includes('*') || value.includes(':') || value.includes('/'))
  ) {
    return {
      type: 'file',
      level: 'error',
      message: `invalidFileName`,
      value: value,
      rule: rule
    };
  }

  return null;
};

const validateAlias = (value, rule) => {
  if (rule && !/^[a-z0-9]{0,16}$/.test(value)) {
    return {
      type: 'alias',
      level: 'error',
      message: 'invalidAlias'
    };
  }

  return null;
};

const validateEndpoint = (value, rule) => {
  if (rule && !new RegExp(/^(?!-)[A-Za-z0-9-]+([-.][a-z0-9]+)*\.[A-Za-z]{2,6}$/).test(value)) {
    return {
      type: 'endpoint',
      level: 'error',
      message: 'invalidEndpoint'
    };
  }

  return null;
};

const validateIpV4 = (value, rule) => {
  if (
    rule
    && !/^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm.test(
      value
    )
  ) {
    return {
      type: 'ipV4',
      level: 'error',
      message: 'invalidIpV4'
    };
  }

  return null;
};

const validateIpV6 = (value, rule) => {
  if (
    rule
    && !/([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])/.test(
      value
    )
  ) {
    return {
      type: 'ipV6',
      level: 'error',
      message: 'invalidIpV6'
    };
  }

  return null;
};

const validateEmail = (value, rule) => {
  if (
    rule
    && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(
      value
    )
  ) {
    return {
      type: 'email',
      level: 'error',
      message: 'invalidEmail'
    };
  }
};

const validateUuid = (value, rule) => {
  if (
    rule
    && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
      value
    )
  ) {
    return {
      type: 'uuid',
      level: 'error',
      message: 'invalidUUID'
    };
  }
};

const validators = {
  required: validateRequired,
  pattern: validatePattern,
  maxByte: validateMaxByte,
  uniqueIn: validateUniqueIn,
  url: validateUrl,
  fullUrl: validateFullUrl,
  urlPrefix: validateUrlPrefix,
  originPath: validateOriginPath,
  same: validateSame,
  different: validateDifferent,
  folder: validateFolder,
  filePath: validateFilePath,
  alias: validateAlias,
  endpoint: validateEndpoint,
  ipV4: validateIpV4,
  ipV6: validateIpV6,
  email: validateEmail,
  uuid: validateUuid
};

const getValidatorsByRules = (rules) =>
  Object.keys(rules)
    .filter((key) => rules[key])
    .map((key) => (value) => validators[key](value, rules[key]));

function validateInput(value, rules) {
  for (let validator of getValidatorsByRules(rules)) {
    const result = validator(value);
    if (result != null) return result;
  }

  return null;
}

export default validateInput;
