const convertToByte = (byte) => {
  const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const e = Math.floor(Math.log(byte) / Math.log(1024));

  const result = parseFloat((byte / Math.pow(1024, e)).toFixed(2));
  if (isNaN(result)) {
    return '0 B';
  }

  return result + ' ' + s[e];
};

export default convertToByte;
