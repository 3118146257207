import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { retryToUploadFile } from '@/store/job';
import { startSingleUpload, UPLOAD_STATUS } from '@/utils/upload';
import convertToByte from '@/utils/convertToByte';
import { toastTypesDic, useToast } from '@/hooks';
import { useQueryToGetProject } from '@/hooks/queries/useQueryToGetProject';
import { useQueryToListCdns } from '@/hooks/queries/useQueryToListCdns';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import useUploadDetailModal from '@/components/myJob/hooks/useUploadDetailModal';
import {
  IconOpenInNewSmall,
  IconFile,
  IconEmptyData,
  IconRetry,
  IconPurge
} from '@mzc-cloudplex/icons';
import { Button } from '@packages/legacy-ui';
import { Box } from '@mui/system';
import { Divider, Grid, Typography, ListItem } from '@mui/material';
import theme from '@/styles/theme';
import { List, ListItemText } from '@/components/myJob/tabs/styles/tabs.styled';
import UploadStatus from '@/components/common/statuses/UploadStatus';
import purgeJobService from '@/services/job/purgeJobService';

const UploadTab = ({ purge }) => {
  const { t } = useTranslation('jobs');
  const dispatch = useDispatch();
  const jobState = useSelector((state) => state.job);
  const { toast, setMaxCount } = useToast();
  // const [totalCount] = useState(0);
  const { spaceId, projectId } = useDeliveryLocation();

  const { isLoading: isLoadingToGetProject, data: dataToGetProject } = useQueryToGetProject({
    spaceId: spaceId,
    projectId: projectId
  });

  const { isLoading: isLoadingToListCdns, data: dataToListCdns } = useQueryToListCdns(
    {
      spaceId: spaceId,
      projectId: projectId
    },
    { excludedServices: ['CUSTOM'] },
    0,
    999
  );

  const uploadFileList = useMemo(() => [...jobState.uploadFiles].reverse(), [jobState.uploadFiles]);
  const isLoading = isLoadingToGetProject || isLoadingToListCdns;

  useEffect(() => {
    setMaxCount(6);
  }, []);

  const { Modal: UploadDetailModal, show: showUploadDetailModal } = useUploadDetailModal();

  const handleRetryButtonClick = (params) => {
    startSingleUpload(
      params.dispatch,
      spaceId,
      projectId,
      params.region,
      params.bucketName,
      params.path,
      params.file,
      params.isLastedFile
    );

    dispatch(
      retryToUploadFile({
        name: params.file.name,
        path: params.path,
        size: convertToByte(params.file.size)
      })
    );
  };

  const getActionsButton = (uploadFile) => {
    switch (uploadFile.status) {
      case UPLOAD_STATUS.ERROR:
        return (
          <Button
            type='button'
            variant={'text'}
            color={'secondary'}
            size={'small'}
            startIcon={<IconRetry size={16} />}
            disabled={isLoading}
            onClick={() => handleRetryButtonClick(uploadFile.params)}
          >
            <span>{t('retry')}</span>
          </Button>
        );
      case UPLOAD_STATUS.COMPLETED:
        return <></>;
      default:
        return <></>;
    }
  };

  const [topDivider, setTopDivider] = useState(false);
  const handleScrolling = (e) => {
    if (e === undefined) {
      return;
    }
    const { scrollTop } = e.currentTarget;

    setTopDivider(scrollTop !== 0);
  };

  return (
    <>
      <Grid container height={`100%`} flexDirection={'column'} flexWrap={'nowrap'}>
        {uploadFileList.length > 0 && (
          <>
            <Grid item>
              <Grid
                container
                alignItems={`center`}
                justifyContent={`flex-end`}
                p={1.5}
                sx={{
                  boxShadow: topDivider ? `0 3px 6px 0 rgba(0, 0, 0, 0.16);` : `none`
                }}
              >
                <Grid item>
                  <Typography variant={'body1'} component={'span'}>
                    {purgeJobService.lastLoadTime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
        <Grid item xs pl={3} pr={3} pt={1.5} sx={{ overflowY: `auto` }} onScroll={handleScrolling}>
          {uploadFileList.length < 1 ? (
            <Box pt={4} pb={4} textAlign={'center'}>
              <IconEmptyData size={58} style={{ color: theme.palette.grayscale[300] }} />
              <Typography mt={2}>{t('noOperation')}.</Typography>
            </Box>
          ) : (
            uploadFileList.map((uploadFile, index) => (
              <div key={`jobs-${index}`}>
                <Grid container alignItems={`center`} justifyContent={'space-between'}>
                  <Grid item>
                    <UploadStatus status={uploadFile.status} />
                  </Grid>
                  <Grid item>{getActionsButton(uploadFile)}</Grid>
                </Grid>
                <Divider sx={{ marginTop: 1 }} />
                <Box sx={{ paddingBottom: 4 }}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemText
                        sx={{ my: 0.5 }}
                        disableTypography
                        primary={
                          <Typography className={'MuiListItemText-primary'} sx={{ pt: 0.5 }}>
                            {t('object')}
                          </Typography>
                        }
                        secondary={
                          <Button
                            type='solid'
                            color={'inherit'}
                            onClick={(e) => {
                              e.preventDefault();
                              showUploadDetailModal({ targetObject: uploadFile });
                            }}
                            startIcon={<IconFile size={16} htmlColor={'#9fa3b2'} />}
                            endIcon={<IconOpenInNewSmall size={16} htmlColor={'#11193f'} />}
                            sx={{ margin: -0.5 }}
                          >
                            <Typography className={'MuiListItemText-secondary'}>
                              {uploadFile.name}
                            </Typography>
                          </Button>
                        }
                      />
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemText
                        sx={{ my: 0.5 }}
                        disableTypography
                        primary={
                          <Typography className={'MuiListItemText-primary'}>
                            {t('project')}
                          </Typography>
                        }
                        secondary={dataToGetProject?.name}
                      />
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemText
                        sx={{ my: 0.5 }}
                        primary={t('size')}
                        secondary={uploadFile.size}
                      />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem disablePadding>
                      <ListItemText
                        sx={{ my: 0.5 }}
                        primary={t('startedAt')}
                        secondary={uploadFile.startTime}
                      />
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemText
                        sx={{ my: 0.5 }}
                        primary={t('completedAt')}
                        secondary={uploadFile.completedTime}
                      />
                    </ListItem>
                  </List>
                </Box>
              </div>
            ))
          )}
        </Grid>
      </Grid>
      <UploadDetailModal />
    </>
  );
};
export default UploadTab;
