// noinspection DuplicatedCode

import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { CDN_SORTING_ORDER } from '@/constants';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import { CDN_SERVICES } from '@/services/cdn/cdnService';
import tryCatch from '@/services/infrastructure/tryCatch';

const sortCdnWeights = (cdns) => {
  const sortingWeights = [];
  let etcIndex = 0;
  cdns.forEach((item) => {
    let index = CDN_SORTING_ORDER.findIndex((cdn) => cdn === item.service);
    if (item.service === CDN_SERVICES.CUSTOM) {
      index = index + etcIndex;
    }
    sortingWeights[index] = item;
    etcIndex++;
  });
  return sortingWeights.filter(Boolean);
};

export const createKeyForListCdns = (
  { spaceId, projectId },
  { services, excludedServices },
  page,
  size
) => [`/spaces/${spaceId}/projects/${projectId}/cdns`, page, size, services, excludedServices];

export const useQueryToListCdns = (
  { spaceId, projectId },
  { services, excludedServices },
  page,
  size,
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListCdns({ spaceId, projectId }, { services, excludedServices }, page, size),
    async (context) =>
      tryCatch(async () => {
        const result = await ApiFactory.cpdV3({
          method: 'get',
          url: `/cdns`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          params: {
            page: page,
            size: size,
            services: services,
            excludedServices: excludedServices
          },
          signal: context.signal
        });
        result.data.results = sortCdnWeights(result.data.results);
        return result.data;
      }, exceptionHandler),
    { ...options }
  );
