import React, { createContext, useEffect } from 'react';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import { useQueryToGetMyInfoInProject } from '@/hooks/queries/useQueryToGetMyInfoInProject';
import useEventObserver, { JOB_EVENTS } from '@/hooks/useEventObserver';
import { useTranslation } from 'react-i18next';
import { toastTypesDic, useToast } from '@/hooks';
import { RECORD_JOB_STATUS } from '@/services/dns/apiV3/dnsRecordService';

const MyEventContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {}
});

const MyEventContextProvider = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { spaceId, projectId } = useDeliveryLocation();
  const { data: dataToGetMyInfoInProject } = useQueryToGetMyInfoInProject({
    spaceId,
    projectId
  });

  const { subscribe, unsubscribe } = useEventObserver({
    prefix: 'user-',
    topic: dataToGetMyInfoInProject?.user?.id
  });

  const onRecordJob = (messageBody) => {
    switch (messageBody.status) {
      case RECORD_JOB_STATUS.COMPLETED:
        return toast(t('dns::message.recordJobCompleted'), { type: toastTypesDic.success });
      case RECORD_JOB_STATUS.FAILED:
        return toast(t('dns::message.recordJobFailed'), { type: toastTypesDic.error });
      default:
        break;
    }
  };
  useEffect(() => {
    subscribe(JOB_EVENTS.RECORD_JOB, onRecordJob);
    return () => unsubscribe(JOB_EVENTS.RECORD_JOB, onRecordJob);
  }, []);

  return (
    <MyEventContext.Provider {...props} value={{ subscribe, unsubscribe }}>
      {children}
    </MyEventContext.Provider>
  );
};

const MyEventObserver = {
  context: MyEventContext,
  Provider: MyEventContextProvider
};

export default MyEventObserver;
