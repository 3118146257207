import React from 'react';
import { INFORMATION_CONTENT_WIDTH } from '@/components/layouts/informationPanel/styles/informationPanel.styled';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CdnInfo = () => {
  const { t } = useTranslation(['cdn']);
  const cdnUsageList = [
    'usage1',
    'usage2',
    'usage3',
    'usage4',
    'usage5',
    'usage6',
    'usage7',
    'usage8',
    'usage9',
    'usage10'
  ];
  const healthCheckUsageList = ['usage1', 'usage2', 'usage3', 'usage4'];
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '8px',
        width: `${INFORMATION_CONTENT_WIDTH}px`
      }}
    >
      <Typography>{t(`info.header`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.usage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {cdnUsageList.map((usage) => (
          <ListItem key={usage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`info.${usage}`)}</Typography>
          </ListItem>
        ))}
      </List>

      <Typography>{t(`info.endpointSetting`)}</Typography>

      <Typography variant={'h6'}>{t(`healthCheck.label`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {healthCheckUsageList.map((usage) => (
          <ListItem key={usage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`healthCheck.${usage}`)}</Typography>
          </ListItem>
        ))}
      </List>

      <Typography>{t(`info.footer`)}</Typography>
    </Grid>
  );
};
export default CdnInfo;
