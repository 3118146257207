import { useQueryClient } from 'react-query';
import { createKeyForGetCompareJob } from '@/hooks/queries/useQueryToGetCompareJobV4';
import { useCallback } from 'react';
import moment from 'moment';
import { COMPARE_JOB_RESULT } from '@/services/job/compareJobService';

const useCompareJobViewModelBuilder = (props) => {
  const queryClient = useQueryClient();

  const refresh = async () => {
    await queryClient.invalidateQueries(
      createKeyForGetCompareJob({
        spaceId: props.spaceId,
        projectId: props.projectId,
        jobId: props.jobId
      })
    );
  };

  return useCallback(
    (source) => {
      return {
        ...source,
        refresh: refresh,
        project: {
          id: source?.project?.id
        },
        creator: {
          ...source?.creator,
          name: source?.creator?.name ?? '',
          username: source?.creator?.username ?? ''
        },
        getResult: () => {
          if (source?.summary == null) return COMPARE_JOB_RESULT.PREPARED;
          if (source?.failReason != null) return COMPARE_JOB_RESULT.ALL_FAILED;

          const { totalCount, inProgressCount, identicalCount, differentCount, failedCount } =
            source.summary;

          if (source?.completedAt != null) {
            if (identicalCount === totalCount) {
              return COMPARE_JOB_RESULT.ALL_IDENTICAL;
            } else if (identicalCount + differentCount === totalCount) {
              return COMPARE_JOB_RESULT.INCLUDE_DIFFERENT;
            } else if (failedCount === totalCount) {
              return COMPARE_JOB_RESULT.ALL_FAILED;
            } else if (identicalCount + differentCount + failedCount === totalCount) {
              return COMPARE_JOB_RESULT.INCLUDE_FAILED;
            } else if (
              totalCount === 0 &&
              inProgressCount === 0 &&
              identicalCount === 0 &&
              differentCount === 0 &&
              failedCount === 0
            ) {
              return COMPARE_JOB_RESULT.ALL_IDENTICAL;
            }
          }
          if (
            totalCount === 0 &&
            inProgressCount === 0 &&
            identicalCount === 0 &&
            differentCount === 0 &&
            failedCount === 0
          )
            return COMPARE_JOB_RESULT.PREPARED;
          return COMPARE_JOB_RESULT.IN_PROGRESS;
        },
        summary: {
          totalCount: source?.summary?.totalCount ?? 0,
          inProgressCount: source?.summary?.inProgressCount ?? 0,
          identicalCount: source?.summary?.identicalCount ?? 0,
          differentCount: source?.summary?.differentCount ?? 0,
          failedCount: source?.summary?.failedCount ?? 0
        },
        createdAt:
          source?.createdAt == null
            ? ''
            : moment.utc(source.createdAt).local().format('YYYY-MM-DD HH:mm:ss'),
        completedAt:
          source?.completedAt == null
            ? ''
            : moment.utc(source.completedAt).local().format('YYYY-MM-DD HH:mm:ss')
      };
    },
    [props]
  );
};

export default useCompareJobViewModelBuilder;
