import React from 'react';
import HeaderForNoting from "@/components/layouts/header/HeaderForNoting";
import {Toolbar} from "@mui/material";
import theme from "@/styles/theme";
import {Content, Documents} from "@/components/layouts/layout.styled";

const LayoutForNothing = ({children}) => (
  <Documents theme={theme} style={{background: theme.palette.background.default, flexDirection: `column`}}>
    <HeaderForNoting/>
    <Toolbar/>
    <Content>
      {children}
    </Content>
  </Documents>
);
export default LayoutForNothing;


