import { styled } from '@mui/material/styles';
import { Grid as MuiGrid, Box as MuiBox } from '@mui/material';

export const FooterWrapper = styled(MuiGrid)(({ theme }) => ({
  position: `relative`,
  minHeight: `48px`,
  margin: `0 auto`,
  padding: `8px 40px`,

  ' > .MuiDivider-root': {
    position: `absolute`,
    left: `40px`,
    right: `40px`,
    top: 0
  },

  '.MuiButtonBase-root': {
    whiteSpace: `nowrap`
  },
  address: {
    fontSize: `12px`,
    color: theme.palette.secondary[600]
  },
  '.MuiGrid-root': {
    '.MuiDivider-root': {
      height: `16px`,
      margin: `0 10px`
    }
  },

  [theme.breakpoints.down(1200)]: {
    alignItems: `flex-start`,
    '.MuiDivider-root': {
      width: `auto`,
      margin: `0 8px`
    },
    '&-text.MuiButtonBase-root': {
      minWidth: `auto`,
      paddingLeft: `8px`,
      paddingRight: `8px`
    },
    '.divider': {
      display: `none`
    },
    '.address': {
      marginTop: `10px`,
      width: `100%`
    }
  },
  [theme.breakpoints.down(960)]: {
    flexDirection: `column`,
    alignItems: `flex-start`,
    '> .MuiGrid-root.MuiGrid-item:last-of-type': {
      marginTop: `32px`,
      flex: `1`,
      alignSelf: `flex-end`
    },
    '.divider': {
      display: `none`
    }
  }
}));

export const Box = styled(MuiBox)(({ theme }) => ({
  display: `flex`,
  alignItems: 'center'
}));

export const LanguageSelectButton = styled(`button`)(({ theme, active }) => ({
  fontSize: `12px`,
  display: `inline-flex`,
  alignItems: `center`,
  strong: {
    margin: `0 5px `,
    whiteSpace: `nowrap`
  },
  ...(active && {
    color: theme.palette.primary.main
  })
}));
