import { handleActions } from 'redux-actions';

const SET_ID = 'project/SET_ID';

const initialState = {
  id: ''
};

export default handleActions(
  {
    [SET_ID]: (state, { payload }) => ({
      ...state,
      id: payload.id
    })
  },
  initialState
);

export const setProjectId =
  ({ projectId }) =>
  (dispatch) => {
    dispatch({
      type: SET_ID,
      payload: { id: projectId }
    });
  };
