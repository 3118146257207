import React from 'react';
import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { INFORMATION_CONTENT_WIDTH } from '@/components/layouts/informationPanel/styles/informationPanel.styled';

const StorageInfo = () => {
  const { t } = useTranslation('storage');
  const usageList = ['usage1', 'usage2', 'usage3', 'usage4'];
  const coverageList = ['coverage1', 'coverage2', 'coverage3', 'coverage4'];
  const corsReference =
    'https://docs.aws.amazon.com/ko_kr/sdk-for-javascript/v2/developer-guide/cors.html';
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '8px',
        width: `${INFORMATION_CONTENT_WIDTH}px`
      }}
    >
      <Typography>{t(`info.header`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.usage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {usageList.map((usage) => (
          <ListItem key={usage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`info.${usage}`)}</Typography>
          </ListItem>
        ))}
      </List>

      <Typography variant={'h6'}>{t(`common::label.coverage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {coverageList.map((coverage) => (
          <ListItem key={coverage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`info.${coverage}`)}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ padding: '2px' }}>
          <Link href={corsReference} target={'_blank'}>
            {corsReference}
          </Link>
        </ListItem>
      </List>
    </Grid>
  );
};
export default StorageInfo;
