import menuService from '@/services/menuService';
import {
  IconAlbum,
  IconAnalytics,
  IconCdn,
  IconCompare,
  IconDashboard,
  IconDns,
  IconMyLocation,
  IconNotice,
  IconPurge,
  IconRoute,
  IconServer,
  IconStorage,
  IconSummary,
  IconUser
} from '@mzc-cloudplex/icons';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import permissionService from '@/services/permissionService';
import MyInfo from '@/contexts/MyInfo';
import { useTranslation } from 'react-i18next';

const useMenu = ({ spaceId, projectId }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { permissions } = useContext(MyInfo.context);
  const menus = [
    {
      id: 'Summary',
      name: t(`summary::label`),
      path: menuService.paths.summary(spaceId, projectId),
      iconClass: 'sprite sprite-menu-setting',
      icon: <IconSummary size={16} />,
      disabled: false,
      active: true,
      isSelect: () =>
        location.pathname.includes(menuService.paths.summary(spaceId, projectId)) ||
        menuService.paths.projectHome(spaceId, projectId) === location.pathname,
      isVisible: () => true
    },
    {
      id: 'Endpoint',
      name: t(`label.endpoint`),
      path: menuService.paths.endpoint(spaceId, projectId),
      iconClass: 'sprite sprite-menu-dns',
      icon: <IconMyLocation size={16} />,
      disabled: false,
      active: true,
      isSelect: () => location.pathname.includes(menuService.paths.endpoint(spaceId, projectId)),
      isVisible: () =>
        permissionService.permit(permissions, permissionService.permissions.endpoint.detail)
    },
    {
      id: 'Notification',
      name: t(`notification::label`),
      path: menuService.paths.notifications(spaceId, projectId),
      iconClass: 'sprite sprite-menu-setting',
      icon: <IconNotice size={16} />,
      disabled: false,
      active: true,
      // supportedEnvironments: ['development'],
      isSelect: () =>
        location.pathname.includes(menuService.paths.notifications(spaceId, projectId)),
      isVisible: () =>
        permissionService.permit(permissions, permissionService.permissions.notification.list)
    },
    {
      id: 'Access',
      name: t(`label.access`),
      path: '',
      divider: true,
      active: false,
      isSelect: () => false,
      isVisible: () =>
        permissionService.permit(permissions, permissionService.permissions.projectUser.list),
      subMenus: [
        {
          id: 'Users',
          name: t(`users::label`),
          path: menuService.paths.projectUsers(spaceId, projectId),
          iconClass: 'sprite sprite-menu-user',
          icon: <IconUser size={16} />,
          disabled: false,
          active: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.projectUsers(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.projectUser.list)
        }
      ]
    },
    {
      id: 'Manage',
      name: t(`label.resource`),
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        permissionService.permit(permissions, [
          ...permissionService.permissions.dns.detail,
          ...permissionService.permissions.cdn.list,
          ...permissionService.permissions.routingPolicy.list,
          ...permissionService.permissions.storage.detail
        ]),
      subMenus: [
        {
          id: 'DNS',
          name: t(`dns::label`),
          path: menuService.paths.dns(spaceId, projectId),
          iconClass: 'sprite sprite-menu-dns',
          icon: <IconDns size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.dns(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.dns.detail)
        },
        {
          id: 'CDN',
          name: t(`cdn::label`),
          path: menuService.paths.cdns(spaceId, projectId),
          iconClass: 'sprite sprite-menu-cdn',
          icon: <IconCdn size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.cdns(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.cdn.list)
        },
        {
          id: 'Routing',
          name: t(`routingPolicy::label`),
          path: menuService.paths.routingPolicies(spaceId, projectId),
          iconClass: 'sprite sprite-menu-route',
          icon: <IconRoute size={16} />,
          disabled: false,
          active: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.routingPolicies(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.routingPolicy.list)
        },
        {
          id: 'Storage',
          name: t(`storage::label`),
          path: menuService.paths.storage(spaceId, projectId),
          iconClass: 'sprite sprite-menu-storage',
          icon: <IconStorage size={16} />,
          disabled: false,
          active: true,
          isSelect: () => location.pathname.includes(menuService.paths.storage(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.storage.detail)
        }
      ]
    },
    {
      id: 'jobs',
      name: t(`jobs::label`),
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        permissionService.permit(permissions, [
          ...permissionService.permissions.compareJob.list,
          ...permissionService.permissions.purgeJob.list
        ]),
      subMenus: [
        {
          id: 'RecordJob',
          name: t(`jobs::dnsZone`),
          path: menuService.paths.recordJobs(spaceId, projectId),
          iconClass: 'sprite sprite-menu-setting',
          icon: <IconAlbum size={16} />,
          disabled: false,
          active: true,
          divider: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.recordJobs(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.purgeJob.list)
        },
        {
          id: 'PurgeJob',
          name: t(`jobs::purgeJob`),
          path: menuService.paths.purgeJobs(spaceId, projectId),
          iconClass: 'sprite sprite-menu-setting',
          icon: <IconPurge size={16} />,
          disabled: false,
          active: true,
          divider: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.purgeJobs(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.purgeJob.list)
        },
        {
          id: 'CompareJob',
          name: t(`jobs::compareJob`),
          path: menuService.paths.compareJobs(spaceId, projectId),
          iconClass: 'sprite sprite-menu-setting',
          icon: <IconCompare size={16} />,
          disabled: false,
          active: true,
          divider: true,
          isSelect: () =>
            location.pathname.includes(menuService.paths.compareJobs(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.compareJob.list)
        }
      ]
    },
    {
      id: 'Origin',
      name: t(`label.origin`),
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        permissionService.permit(permissions, permissionService.permissions.originStorage.awsProxy),
      subMenus: [
        {
          id: 'OriginStorage',
          name: t(`originStorage::label`),
          path: menuService.paths.originStorage(spaceId, projectId),
          iconClass: 'sprite sprite-menu-origin-storage',
          icon: <IconServer size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.originStorage(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(
              permissions,
              permissionService.permissions.originStorage.awsProxy
            )
        }
      ]
    },
    {
      id: 'cdnMetrics',
      name: t(`cdnMetrics::label`),
      active: false,
      divider: true,
      path: '',
      isSelect: () => false,
      isVisible: () =>
        permissionService.permit(permissions, [...permissionService.permissions.cdnMetrics.list]),
      subMenus: [
        {
          id: 'periodicMetrics',
          name: t(`cdnMetrics::periodicMetrics.label`),
          path: menuService.paths.cdnMetrics(spaceId, projectId),
          icon: <IconAnalytics size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.cdnMetrics(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.cdnMetrics.list)
        },
        {
          id: 'monitoring',
          name: t(`cdnMetrics::monitoring.label`),
          path: menuService.paths.cdnMonitoring(spaceId, projectId),
          iconClass: 'sprite sprite-menu-origin-storage',
          icon: <IconDashboard size={16} />,
          disabled: true,
          active: false,
          isSelect: () =>
            location.pathname.includes(menuService.paths.cdnMonitoring(spaceId, projectId)),
          isVisible: () =>
            permissionService.permit(permissions, permissionService.permissions.cdnMetrics.list)
        }
      ]
    }
  ];

  return {
    menus: menus
  };
};

export default useMenu;
