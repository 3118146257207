import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';
import { createQueryKey } from '@/utils/createQueryKey';

export const createKeyForListCompareJobTasks = ({ spaceId, projectId, jobId }, params) =>
  createQueryKey([`/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}/tasks`], params);

export const useQueryToListCompareJobTasks = (
  { spaceId, projectId, jobId },
  { page, size, statuses },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) => {
  return useQuery(
    createKeyForListCompareJobTasks({ spaceId, projectId, jobId }, { page, size, statuses }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV4({
          method: 'get',
          url: `/compare-jobs/${jobId}/tasks`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          params: {
            page: page,
            size: size,
            statuses: statuses
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
};
