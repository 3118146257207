import React from 'react';
import { Box } from '@mzc-pdc/ui';

export const IconBox = (props: {
  children: React.ReactElement;
  size?: number;
  backgroundColor?: string;
  borderRadius?: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: props.size ?? 16,
      height: props.size ?? 16,
      borderRadius: props.borderRadius ?? '25%',
      backgroundColor: props.backgroundColor ?? 'transparent'
    }}
  >
    {props.children}
  </Box>
);
