import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToggleSwitchTabButton } from '@packages/legacy-ui';
import { IconHierarchy, IconText } from '@mzc-cloudplex/icons';
import { alpha, Box } from '@mui/system';
import theme from '@/styles/theme';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';

const ReactJsonEx = (props) => (
  <ReactJson
    {...props}
    enableClipboard={(copy) => {
      navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
    }}
  />
);

const JsonViewer = ({ title, json }) => {
  // pdc-core:: tab component의 value 프로퍼티가 기본값인 숫자만 사용하도록 개발되어있음.
  // const VIEW_TYPE_JSON = 'JSON';
  // const VIEW_TYPE_TEXT = 'TEXT';
  const VIEW_TYPE_JSON = 0;
  const VIEW_TYPE_TEXT = 1;
  const { t } = useTranslation('jobs');
  const [viewType, setViewType] = useState(VIEW_TYPE_JSON);

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'space-between'} flexWrap={'nowrap'}>
        <Grid item>
          <Typography variant={'subtitle1'} component={'p'} fontWeight={'500'}>
            {title}
          </Typography>
        </Grid>
        {/* 아...... 컴포넌트 프로퍼티.. 진짜.. 숫자로 할거면 options는 왜 받는거임??  */}
        <ToggleSwitchTabButton
          onChange={(event, value) => setViewType(value)}
          value={viewType}
          options={[
            {
              icon: <IconHierarchy size={16} />,
              label: t('hierarchy'),
              value: VIEW_TYPE_JSON
            },
            {
              icon: <IconText size={16} />,
              label: t('text'),
              value: VIEW_TYPE_TEXT
            }
          ]}
        />
      </Grid>
      <Box
        mt={1.5}
        mb={3}
        p={1.5}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: `4px`,
          backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.24),
          overflowX: `auto`
        }}
      >
        {viewType === VIEW_TYPE_TEXT ? (
          <pre>{JSON.stringify(json, null, '\t')}</pre>
        ) : (
          <ReactJsonEx src={json} iconStyle={'circle'} collapsed={1} name={false} />
        )}
      </Box>
    </>
  );
};

export default JsonViewer;
