import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetMyInfo = () => [`/my-info`];
export const useQueryToGetMyInfo = (
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetMyInfo(),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: '/users/me',
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    {
      staleTime: 1000 * 5, // 5초 캐쉬 사용
      cacheTime: 1000 * 5,
      ...options
    }
  );
