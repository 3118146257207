import { useQueryToGetProject } from '@/hooks/queries/useQueryToGetProject';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import theme from '@/styles/theme';

const ProjectName = ({ spaceId, projectId }) => {
  const { t } = useTranslation();
  const { data: dataToGetProject, isLoading } = useQueryToGetProject(
    {
      spaceId: spaceId,
      projectId: projectId
    },
    {
      enabled: !!projectId
    },
    {
      handle: () => {
        // ignore
      }
    }
  );
  if (projectId == null) return <></>;

  return (
    <Typography component={'span'} variant={'subtitle2'}>
      {isLoading ? (
        <Skeleton variant={'text'} height={22} width={'100%'} />
      ) : (
        <>
          {dataToGetProject?.name ?? t('project.unknown')}
          <Typography component={'span'} variant={'subtitle2'} color={theme.palette.text.secondary}>
            ({projectId})
          </Typography>
        </>
      )}
    </Typography>
  );
};

export default ProjectName;
