import {Menu as MuiMenu, styled, Toolbar as MuiToolbar} from "@mui/material";

export const Menu = styled(MuiMenu)`
  .MuiPaper-root {
    width: 171px;
    color: ${(props) => props.theme.palette.common.black};
    a {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.palette.common.black};
      &:hover {
        text-decoration: none;
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
    .MuiButtonBase-root {
      &:hover {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
    svg {
      margin-right: 10px;
    }
  }
`;

export const Toolbar = styled(MuiToolbar)`
  ${(props) => props.theme.breakpoints.up(`sm`)} {
    padding-right: 0;
    padding-left: 0;
  }
`;
