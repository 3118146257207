import React from 'react';
import theme from "@/styles/theme";
import {Documents, EmptyContent} from "@/components/layouts/layout.styled";
import PropTypes from "prop-types";
import Footer from "@/components/layouts/footer/Footer";

const LayoutForWelcome = ({ children }) => {
  LayoutForWelcome.propTypes = {
    children: PropTypes.any
  };

  return (
    <Documents theme={theme} style={{background: theme.palette.background.default, flexDirection: `column`}}>
      <EmptyContent>{children}</EmptyContent>
      <Footer />
    </Documents>
  );
};
export default LayoutForWelcome;
