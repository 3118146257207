import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import theme from '@/styles/theme';
import StatusBadge from '@/components/manage/common/StatusBadge';

const UploadDetailModal = ({ targetObject }) => (
  <>
    <Box>
      <StatusBadge status={targetObject.status} />
    </Box>
    <Box
      mt={1}
      mb={2}
      sx={{
        maxHeight: `300px`,
        overflowY: `overlay`,
        border: `1px solid ${theme.palette.divider}`
      }}
    >
      <List dense sx={{ padding: 0 }}>
        <ListItem>
          <ListItemText
            primary={`${targetObject.path}${targetObject.name}`}
            secondary={
              targetObject.status === 'ERROR' && (
                <Typography color={'error'}>{/* Error 사유 */}</Typography>
              )
            }
            sx={{
              display: `flex`,
              alignItems: `center`,
              gap: 1,
              'span, p': {
                fontSize: `14px`
              },
              '.MuiListItemText-primary': {
                maxWidth: `80%`,
                textOverflow: `ellipsis`,
                whiteSpace: `nowrap`,
                overflow: `hidden`
              }
            }}
          />
        </ListItem>
      </List>
    </Box>
  </>
);

export default UploadDetailModal;
