import { useCallback, useMemo } from 'react';

export const useCdnLabelProvider = () => {
  const cdns = useMemo(
    () => [
      {
        platform: 'AWS',
        service: 'AWS_CLOUDFRONT',
        label: 'Amazon Cloudfront',
        isConnectable: true
      },
      { platform: 'AKAMAI', service: 'AKAMAI', label: 'Akamai CDN', isConnectable: true },
      { platform: 'LIMELIGHT', service: 'LIMELIGHT', label: 'Edgio CDN', isConnectable: true },
      { platform: 'AZURE', service: 'AZURE_EDGIO', label: 'Azure CDN', isConnectable: false },
      { platform: 'KT', service: 'KT_SOLBOX', label: 'KT Solbox', isConnectable: true },
      {
        platform: 'ALIBABA_CLOUD',
        service: 'ALIBABA_CLOUD_CDN',
        label: 'Alibaba Cloud CDN',
        isConnectable: true
      },
      {
        platform: 'TENCENT_CLOUD',
        service: 'TENCENT_CLOUD_CDN',
        label: 'Tencent Cloud CDN',
        isConnectable: true
      },
      {
        platform: 'TENCENT_CLOUD',
        service: 'TENCENT_CLOUD_EDGEONE',
        label: 'Tencent Cloud EdgeOne',
        isConnectable: true
      },
      {
        platform: 'TENCENT_CLOUD',
        service: 'TENCENT_CSS',
        label: 'Tencent CSS',
        isConnectable: true
      },
      { platform: 'LG_UPLUS', service: 'LG_UPLUS', label: 'LGU+ Solbox', isConnectable: true },
      { platform: 'LG_UPLUS', service: 'LG_NIMBUS', label: 'LGU+ Nimbus', isConnectable: false },
      {
        platform: 'GOOGLE_CLOUD',
        service: 'GOOGLE_CLOUD_CDN',
        label: 'Google Cloud CDN',
        isConnectable: false
      },
      {
        platform: 'CLOUDFLARE',
        service: 'CLOUDFLARE_CDN',
        label: 'Cloudflare CDN',
        isConnectable: true
      },
      {
        platform: 'HYOSUNG_ITX',
        service: 'HYOSUNG_ITX_CDN',
        label: 'Hyosung ITX CDN',
        isConnectable: false
      },
      { platform: 'BYTEPLUS', service: 'BYTEPLUS_CDN', label: 'BytePlus CDN', isConnectable: true },
      {
        platform: 'HUAWEI_CLOUD',
        service: 'HUAWEI_CLOUD_CDN',
        label: 'Huawei Cloud CDN',
        isConnectable: true
      },
      {
        platform: 'SK_BROADBAND',
        service: 'SK_BROADBAND_CDN',
        label: 'SK Broadband CDN',
        isConnectable: true
      },
      {
        platform: 'CLOUDPLEX',
        service: 'CLOUDPLEX_MEGAZONE_CDN',
        label: 'Megazone CDN',
        isConnectable: true
      },
      { platform: 'CUSTOM', service: 'CUSTOM', label: 'Custom CDN', isConnectable: false }
    ],
    []
  );

  const getCdn = useCallback((service) => cdns.find((item) => item.service === service), [cdns]);

  return {
    cdns: cdns,
    get: getCdn
  };
};
