import _ from 'lodash/fp';

export default (() => {
  const isPromise = (x) => _.isFunction(_.get('then', x) && _.isFunction(_.get('catch', x)));

  const fnPromisify = (fn, ...args) =>
    new Promise((resolve, reject) =>
      fn(...args, (error, value) => (_.isNil(error) ? resolve(value) : reject(error)))
    );

  /**
   * 대상 인자를 promise로 변환
   * - nodejs의 promisify와 유사한 기능
   */
  const promisify = _.cond([
    [_.isFunction, fnPromisify],
    [isPromise, (fn) => fn],
    [_.T, (fn) => Promise.resolve(fn)]
  ]);

  const flatPromise = (thenable) =>
    isPromise(thenable) ? thenable.then((x) => flatPromise(x)) : thenable;
  const then = _.curry((fn, thenable) => promisify(thenable).then(flatPromise(fn)));

  const otherwise = _.curry((fn, thenable) => promisify(thenable).catch(flatPromise(fn)));

  const _finally = _.curry((fn, thenable) => promisify(thenable).finally(flatPromise(fn)));

  const isNotEmpty = (a) => !_.isEmpty(a);
  const not = (a) => !a;
  const toBool = (a) =>
    _.cond([
      [_.equals('true'), _.T],
      [_.equals('false'), _.F],
      [_.T, (a) => !!a]
    ])(a);

  const isJson = (a) => {
    const composer = _.pipe(_.attempt, _.isError);
    return _.isString(a) && !composer(() => JSON.parse(a));
  };
  const pascalCase = (a) => _.pipe(_.camelCase, _.upperFirst)(a);

  /**
   * 삼항식용 helper
   * pipe에서 point free 방식으로 삼항식을 사용하기 위해 추가
   */
  const ternary = _.curry((t, f, is) => (_.equals(is, true) ? _.identity(t) : _.identity(f)));

  /**
   * value로 object key 조회
   */
  const key = _.curry((obj, val) => _.get(val, _.invert(obj)));

  const objectKeyTarnsform = _.curry((transformFn, exceptions, dest) => {
    const convertRecursively = (dest) => {
      const convertTo = (o) => {
        const composer = _.pipe(
          _.keys,
          _.reduce((acc, v) => {
            const cond = _.cond([
              [_.isPlainObject, convertTo],
              [_.isArray, (v) => _.map(cond, v)],
              [_.T, _.identity]
            ]);
            if (!_.includes(v, exceptions)) {
              acc[transformFn(v)] = cond(o[v]);
            } else {
              acc[v] = cond(o[v]);
            }

            return acc;
          }, {})
        );
        return composer(o);
      };
      return convertTo(dest);
    };

    return _.isObject(dest) || _.isArray(dest) ? convertRecursively(dest) : dest;
  });
  const isNotNil = _.pipe(_.isNil, not);
  const toCamelcase = objectKeyTarnsform(_.camelCase, ['2xx', '3xx', '4xx', '5xx']);
  const toSnakecase = objectKeyTarnsform(_.pipe(_.snakeCase, _.replace('s_3', 's3')), []);
  /**
   * applicative functor pattern 참고
   * ref) https://github.com/monet/monet.js/blob/master/docs/MAYBE.md#ap
   * pipe에서 인자의 순서를 역으로 적용하는데 주로 사용
   */
  const ap = _.curry((a, curried) => (_.isFunction(a) ? curried(a()) : curried(a)));
  const mapWithKey = _.map.convert({ cap: false });
  const reduceWithKey = _.reduce.convert({ cap: false });
  const notEquals = _.curry((a, b) => _.pipe(_.equals(a), not)(b));

  _.mixin({
    isPromise,
    promisify,
    then,
    otherwise,
    finally: _finally,
    isNotEmpty,
    not,
    toBool,
    isJson,
    pascalCase,
    ternary,
    key,
    isNotNil,
    toCamelcase,
    toSnakecase,
    ap,
    mapWithKey,
    reduceWithKey,
    notEquals
  });

  return _;
})();
