import { toastTypesDic, useToast } from '@/hooks';
import { CanceledError } from 'axios';
import { uncaught } from '@/services/infrastructure/exceptionAnalyzers/common';
import {
  badRequestForDelivery,
  conflictForDelivery,
  serviceUnavailableForDelivery
} from '@/services/infrastructure/exceptionAnalyzers/delivery';
import { useTranslation } from 'react-i18next';
import menuService from "@/services/menuService";
import {useNavigate} from "react-router-dom";

export const exceptionProcessorForQuery = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return {
    handle: (error) => {
      if (error instanceof CanceledError || error?.response?.status === 404) return false;

      if(error?.response?.status === 401) {
        navigate(menuService.paths.welcome());
        return true;
      }
      if (error?.response?.status === 403) {
        return true;
      }

      const processors = [
        badRequestForDelivery,
        conflictForDelivery,
        serviceUnavailableForDelivery,
        uncaught
      ];
      for (let processor of processors) {
        const messages = processor(error);
        if (messages?.length > 0) {
          messages.forEach((message) => {
            toast(t(message.translation.key, { property: t(message.translation.property) }), {
              type: toastTypesDic.error,
              onClose: () => {}
            });
          });
          return true;
        }
      }
    }
  };
};
