import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const withModal = (InnerComponent) =>
  class Modal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        modalStyle: {
          display: 'block'
        }
      };
      //this.setScrollbarWidth();
    }

    onOpen = () => {
      this.setState({
        modalStyle: {
          display: 'block'
        }
      });
      this.setScrollbarWidth();
    };

    onClose = () => {
      if (!_.isNil(this.setState)) {
        this.setState({
          modalStyle: {
            display: 'none'
          }
        });

        _.isFunction(this.props.initStateEvent) && this.props.initStateEvent();
        document.documentElement.classList.remove('modal-open');
      }
      //document.body.style.paddingRight = '';
    };

    setScrollbarWidth = () => {
      //document.body.style.paddingRight = this.getScrollbarWidth() + 'px';
      document.documentElement.classList.add('modal-open');
    };

    getScrollbarWidth = () => {
      let widthNoScroll = '';
      let inner = '';
      let widthWithScroll = '';
      let outer = document.createElement('div');

      outer.style.visibility = 'hidden';
      outer.style.width = '100px';
      document.body.appendChild(outer);

      widthNoScroll = outer.offsetWidth;
      outer.style.overflow = 'scroll';

      inner = document.createElement('div');
      inner.style.width = '100%';
      outer.appendChild(inner);

      widthWithScroll = inner.offsetWidth;
      outer.parentNode.removeChild(outer);

      return widthNoScroll - widthWithScroll;
    };

    render() {
      return ReactDOM.createPortal(
        <InnerComponent
          onOpen={this.onOpen}
          onClose={this.onClose}
          modalStyle={this.state.modalStyle}
          {...this.props}
        />,
        document.body
      );
    }
  };

export default withModal;
