import { Grid } from '@mzc-pdc/ui';
import React from 'react';

type HeaderContainerProps = {
  header: React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
};
export const HeaderContainer = (props: HeaderContainerProps) => (
  <Grid
    container
    flexDirection={'column'}
    alignItems={'stretch'}
    flexWrap={'nowrap'}
    gap={1}
    sx={{ '& + & ': { marginTop: `20px` } }}
  >
    <Grid item>
      <Grid container alignItems={'center'}>
        <Grid item>{props.icon}</Grid>
        <Grid item>{props.header}</Grid>
      </Grid>
    </Grid>
    <Grid item>{props.children}</Grid>
  </Grid>
);
