import React from 'react';
import HeaderForNoting from "@/components/layouts/header/HeaderForNoting";
import {Toolbar} from "@mui/material";
import theme from "@/styles/theme";
import {Content, Documents} from "@/components/layouts/layout.styled";
import Footer from "@/components/layouts/footer/Footer";

const LayoutForSpaces = ({children}) => (
  <Documents theme={theme} style={{background: theme.palette.background.default, flexDirection: `column`}}>
    <HeaderForNoting/>
    <Toolbar/>
    <Content>
      {children}
    </Content>
    <Footer/>
  </Documents>
);
export default LayoutForSpaces;
