import React, { useEffect, useState } from 'react';
import axios from 'axios';

import withModal from '@/utils/withModal';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button, Dialog, CircleProgress, Tooltip, IconButton } from '@packages/legacy-ui';
import { IconClose, IconDownload } from '@mzc-cloudplex/icons';
import theme from '@/styles/theme';

const AppDownload = ({ onClickClose }) => {
  const { t } = useTranslation();
  // const [active, setActive] = useState(false);
  // const [style, setStyle] = useState({display: 'none'});
  const [apps, setApps] = useState([]);
  const [hyperBrowser, setHyperBrowser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [megazoneCloudPlexMediaCaptionEditor, setMegazoneCloudPlexMediaCaptionEditor] = useState(
    {}
  );
  useEffect(() => {
    // setActive(true);
    // setStyle({display: 'block'});
    // document.documentElement.classList.add('modal-open');
    getAppsInfo();
  }, []);

  const getProductInfo = (axiosInstance, url) => axiosInstance.get(url);

  const close = ((onClickClose) => () => {
    document.documentElement.classList.remove('modal-open');
    _.isFunction(onClickClose) && onClickClose();
  })(onClickClose);

  const getAppsInfo = async () => {
    const targets = [
      { product: 'HyperBrowser', stage: 'Production', languageCode: 'ko-KR' },
      {
        product: 'MegazoneCloudPlexMediaCaptionEditor',
        stage: 'Production',
        languageCode: 'ko-KR'
      }
    ];

    const axiosInstance = axios.create();
    const authorizationHeader = axiosInstance.defaults.headers.common['Authorization'];
    const pragmaHeader = axiosInstance.defaults.headers.common['Pragma'];
    const cacheControlHeader = axiosInstance.defaults.headers.common['Cache-Control'];
    delete axiosInstance.defaults.headers.common['Authorization'];
    delete axiosInstance.defaults.headers.common['Pragma'];
    delete axiosInstance.defaults.headers.common['Cache-Control'];

    for (let i = 0; i < targets.length; i++) {
      const target = targets[i];
      const url = `https://api.hyper.mz.co.kr/communications/versions?product=${target.product}&stage=${target.stage}&languageCode=${target.languageCode}`;

      const response = await getProductInfo(axiosInstance, url);

      const versions = response.data.versions
        .sort(function (a, b) {
          return a.version < b.version ? -1 : a.version > b.version ? 1 : 0;
        })
        .reverse();

      if (versions && versions.length > 0) apps.push(versions[0]);
    }
    axiosInstance.defaults.headers.common['Authorization'] = authorizationHeader;
    axiosInstance.defaults.headers.common['Pragma'] = pragmaHeader;
    axiosInstance.defaults.headers.common['Cache-Control'] = cacheControlHeader;
    setApps(apps);
    setHyperBrowser(apps.find((v) => v.product === 'HyperBrowser'));
    setMegazoneCloudPlexMediaCaptionEditor(
      apps.find((v) => v.product === 'MegazoneCloudPlexMediaCaptionEditor')
    );
  };

  return (
    <Dialog
      open={true}
      width={480}
      modalType={'simple'}
      modalTitle={t(`label.downloadApps`)}
      actions={
        <Grid container justifyContent={'space-between'}>
          <Grid item xs sx={{ textAlign: `right` }}>
            <Tooltip title={t('label.close')}>
              <span>
                <IconButton
                  circled
                  color={'inherit'}
                  size={'medium'}
                  onClick={close}
                  sx={{ position: `absolute`, right: 24, top: 24 }}
                >
                  <IconClose size={16} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      }
    >
      <Grid container flexWrap={'nowrap'} gap={3}>
        <Grid item width={60}>
          <img
            src={`./images/apps-hyper-browser.png`}
            alt={'hyper browser'}
            width={60}
            height={60}
          />
        </Grid>
        <Grid item xs>
          <Typography variant={'h4'} gutterBottom>
            Hyper Browser
          </Typography>
          <Typography variant={'body1'} color={theme.palette.text.secondary} gutterBottom>
            {_.getOr(<CircleProgress size={12} thickness={1} />, 'version', hyperBrowser)}
          </Typography>
          <Typography variant={'body1'} sx={{ mb: 3 }}>
            {t(`label.hyperBrowserIntroduction`)}
            <br />
            {t(`label.hyperBrowserDescriptions`)}
          </Typography>
          <Button
            variant={'outlined'}
            color={'primary'}
            size={'medium'}
            endIcon={<IconDownload size={'16'} />}
            loading={_.isEmpty(hyperBrowser)}
            href={_.getOr('#', 'download.url', hyperBrowser)}
          >
            <strong>{t(`label.download`)}</strong>
            <i className={'sprite sprite-download-app'}></i>
          </Button>
        </Grid>

        {/* <div className={'apps hyper-subtitle-editor'}>
              <h6>Hyper Subtitle Editor</h6>
              <p className={'version'}>
                {_.getOr(
                  <span className='loading-inline-light'></span>,
                  'version',
                  megazoneCloudPlexMediaCaptionEditor
                )}
              </p>
              <p className={'description'}>
                Hyper Subtitle Editor works with the MCM platform to provide flexible workflows from
                subtitling registration to publishing.
                <br />
                Quickly create subtitles with user experience-based UI to gain a wider audience.
              </p>
              <p className={'download'}>
                <a
                  href={_.getOr('#', 'download.url', megazoneCloudPlexMediaCaptionEditor)}
                  className={'btn btn-outline-primary'}
                  r-class={{
                    'btn-is-loading': _.isEmpty(megazoneCloudPlexMediaCaptionEditor)
                  }}
                >
                  <strong>Download</strong>
                  <i className={'sprite sprite-download-app'}></i>
                </a>
              </p>
            </div> */}
      </Grid>
    </Dialog>
  );
};
export default withModal(AppDownload);
