import { useQueryToListMyPurgeJobs } from '@/hooks/queries/useQueryToListMyPurgeJobs';
import { useMemo, useState } from 'react';
import purgeJobService from '@/services/job/purgeJobService';

const useListMyPurgeJobs = ({ spaceId }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    isFetching: isFetchingToListMyPurgeJobs,
    isLoading: isLoadingToListMyPurgeJobs,
    data: dataToListMyPurgeJobs,
    refetch: refetchToListMyPurgeJobs
  } = useQueryToListMyPurgeJobs({ spaceId: spaceId }, page - 1, pageSize, {
    onSuccess: () => purgeJobService.updateLastLoadTime()
  });

  const jobs = useMemo(
    () =>
      (dataToListMyPurgeJobs?.results ?? []).map((item) => purgeJobService.createViewModel(item)),
    [dataToListMyPurgeJobs]
  );

  return {
    jobs: jobs,
    isFetching: isFetchingToListMyPurgeJobs,
    isLoading: isLoadingToListMyPurgeJobs,
    page: page,
    setPage: setPage,
    pageSize: pageSize,
    setPageSize: setPageSize,
    totalCount: dataToListMyPurgeJobs?.totalCount,
    refresh: refetchToListMyPurgeJobs
  };
};

export default useListMyPurgeJobs;
