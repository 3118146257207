import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetPurgeJob = ({ spaceId, projectId, jobId }) => [
  `/spaces/${spaceId}/projects/${projectId}/purge-jobs/${jobId}`
];
export const useQueryToGetPurgeJob = (
  { spaceId, projectId, jobId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetPurgeJob({ spaceId, projectId, jobId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/purge-jobs/${jobId}`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
