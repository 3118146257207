export const cancelable = (handle) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = handle(signal);

  promise.cancel = () => controller.abort();

  return promise;
};
