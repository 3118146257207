import React from 'react';
import { ReactNode } from 'react';

interface ValidConditionContainerProps {
  condition: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}

export const ValidConditionContainer = (props: ValidConditionContainerProps) => {
  if (props.condition) return <>{props.children}</>;
  if (props.fallback) return <>{props.fallback}</>;
  return null;
};
