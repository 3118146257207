export const badRequestForDelivery = (error) => {
  if (error?.response?.status === 400 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items?.map((item) => {
      const message = badRequestMessages[item.code];
      if (message != null) {
        return {
          type: 'error',
          code: item.code,
          message: item.message,
          translation: {
            key: 'errorMessage.' + message
          }
        };
      } else {
        return {
          type: 'error',
          code: item.code,
          message: item.message,
          translation: {
            key: 'INVALID_PROPERTY',
            property: item.code
          }
        };
      }
    });
  }
};

const badRequestMessages = {
  INVALID_ID: 'invalidId',
  INVALID_PROPERTY: 'invalidProperty',
  ILLEGAL_PAYLOAD: 'illegalPayload',
  ILLEGAL_PLATFORM: 'illegalPlatform',
  ILLEGAL_SERVICE: 'illegalService',
  NOT_SUPPORTED_OFFSET_PAGINATION: 'notSupportedOffsetPagination',
  NOT_MATCHED_CDN_HINT: 'notMatchedCdnHint',
  UNAUTHORIZED_AKAMAI: 'unauthorizedAkamai',
  ILLEGAL_USER: 'illegalUser'
};

export const notFoundForDelivery = (error) => {
  if (error?.response?.status === 404 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => notFoundMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: 'errorMessage.' + notFoundMessages[item.code]
        }
      }));
  }
};

const notFoundMessages = {
  NOT_FOUND_AKAMAI_CDN_RESOURCE: 'notFoundAkamaiCdnResource',
  NOT_FOUND_ALIBABA_CDN_RESOURCE: 'notFoundAlibabaCdnResource',
  NOT_FOUND_CDN: 'notFoundCdn',
  NOT_FOUND_CDN_HEALTH_CHECK: 'notFoundCdnHealthCheck',
  NOT_FOUND_CLOUDFLARE_CDN_RESOURCE: 'notFoundCloudflareCdnResource',
  NOT_FOUND_CLOUDFRONT_CDN_RESOURCE: 'notFoundCloudfrontCdnResource',
  NOT_FOUND_DNS: 'notFoundDns',
  NOT_FOUND_KT_SOLBOX_CDN_RESOURCE: 'notFoundKtSolboxCdnResource',
  NOT_FOUND_LG_UPLUS_CDN_RESOURCE: 'notFoundLgUplusCdnResource',
  NOT_FOUND_LIMELIGHT_CDN_RESOURCE: 'notFoundLimelightCdnResource',
  NOT_FOUND_TENCENT_CDN_RESOURCE: 'notFoundTencentCdnResource',
  NOT_FOUND_EDGEONE_CDN_RESOURCE: 'notFoundEdgeoneCdnResource'
};

export const conflictForDelivery = (error) => {
  if (error?.response?.status === 409 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => conflictMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: 'errorMessage.' + conflictMessages[item.code]
        }
      }));
  }
};

const conflictMessages = {
  // dns
  DUPLICATED_CDN_SERVICE: 'duplicatedCdnService',
  DUPLICATED_CUSTOM_CDN: 'duplicatedCustomCdn',
  DUPLICATED_DNS: 'duplicatedDns',
  DUPLICATED_DNS_ZONE: 'duplicatedDnsZone',
  DUPLICATED_ROUTING_POLICY_ALIAS: 'duplicatedRoutingPolicyAlias',
  INVALID_AKAMAI_CDN_OPERATION: 'invalidAkamaiCdnOperation',
  INVALID_ALIBABA_CDN_OPERATION: 'invalidAlibabaCdnOperation',
  INVALID_BYTEPLUS_CDN_OPERATION: 'invalidByteplusCdnOperation',
  INVALID_CLOUDFLARE_CDN_OPERATION: 'invalidCloudflareCdnOperation',
  INVALID_CLOUDFRONT_CDN_OPERATION: 'invalidCloudfrontCdnOperation',
  INVALID_CREDENTIAL_OPERATION: 'invalidCredentialOperation',
  INVALID_DNS_ZONE_OPERATION: 'invalidDnsZoneOperation',
  INVALID_EDGEONE_CDN_OPERATION: 'invalidEdgeoneCdnOperation',
  INVALID_KT_SOLBOX_CDN_OPERATION: 'invalidKtSolboxCdnOperation',
  INVALID_LG_UPLUS_CDN_OPERATION: 'invalidLgUplusCdnOperation',
  INVALID_LIMELIGHT_CDN_OPERATION: 'invalidLimelightCdnOperation',
  INVALID_ROUTE53_DNS_OPERATION: 'invalidRoute53DnsOperation',
  INVALID_SPACE_OPERATION: 'invalidSpaceOperation',
  INVALID_TENCENT_CDN_OPERATION: 'invalidTencentCdnOperation',
  REQUIRED_CDN: 'requiredCdn',
  REQUIRED_CDN_HEALTH_CHECK: 'requiredCdnHealthCheck',
  REQUIRED_CDN_HEALTH_CHECK_FILE_OBJECT: 'requiredCdnHealthCheckFileObject',
  REQUIRED_CDN_HEALTH_CHECK_RESOURCE: 'requiredCdnHealthCheckResource',
  REQUIRED_CDN_IDENTIFIER: 'requiredCdnIdentifier',
  REQUIRED_CDN_RESOURCE: 'requiredCdnResource',
  REQUIRED_CNAME: 'requiredCname',
  REQUIRED_CREDENTIAL: 'requiredCredential',
  REQUIRED_DNS: 'requiredDns',
  REQUIRED_STORAGE: 'requiredStorage',
  REQUIRED_TARGET: 'requiredTarget',
  LIMITED_CUSTOM_CDN_COUNT: 'limitedCustomCdnCount',
  LIMITED_ROUTE53_HEALTH_CHECK_COUNT: 'limitedRoute53HealthCheckCount',
  NOT_PERMITTED_CREDENTIAL: 'notPermittedCredential',
  USED_AS_ACTIVE_ROUTING_POLICY: 'usedAsActiveRoutingPolicy',
  USED_AS_CDN_IN_ROUTING_POLICY: 'usedAsCdnInRoutingPolicy',
  USED_AS_DNS_IN_ROUTING_POLICY: 'usedAsDnsInRoutingPolicy',
  USED_AS_PRIMARY_CDN_IN_ROUTING_POLICY: 'usedAsPrimaryCdnInRoutingPolicy',

  // purge health check
  // Todo: handle duplicate message codes
  // INVALID_CREDENTIAL_OPERATION: 'NOT_PERMITTED_CREDENTIAL',
  INVALID_STORAGE_OPERATION: 'invalidStorageOperation',
  INVALID_DNS_OPERATION: 'invalidDnsOperation',
  REQUIRED_PURGE_HEALTH_CHECK: 'requiredPurgeHealthCheck',
  // REQUIRED_CDN: 'USED_AS_PRIMARY_CDN_IN_ROUTING_POLICY',
  // REQUIRED_CREDENTIAL: 'USED_AS_DNS_IN_ROUTING_POLICY',
  // REQUIRED_DNS: 'USED_AS_DNS_IN_ROUTING_POLICY',
  // REQUIRED_STORAGE: 'USED_AS_DNS_IN_ROUTING_POLICY',

  // dns record
  // Todo: handle duplicate message codes
  // INVALID_STORAGE_OPERATION: 'USED_AS_ACTIVE_ROUTING_POLICY',
  // INVALID_DNS_OPERATION: 'USED_AS_ACTIVE_ROUTING_POLICY',
  // INVALID_ROUTE53_DNS_OPERATION: 'USED_AS_ACTIVE_ROUTING_POLICY',
  // REQUIRED_CDN: 'USED_AS_ACTIVE_ROUTING_POLICY',
  // REQUIRED_DNS: 'USED_AS_ACTIVE_ROUTING_POLICY',
  REQUIRED_ROUTING_POLICY: 'requiredRoutingPolicy',
  // REQUIRED_CREDENTIAL: 'USED_AS_ACTIVE_ROUTING_POLICY',
  // REQUIRED_CDN_HEALTH_CHECK: 'USED_AS_ACTIVE_ROUTING_POLICY',
  REQUIRED_DNS_ZONE: 'requiredDnsZone',
  // notification
  INVALID_TMS_OPERATION: 'invalidTmsOperation'
};

export const serviceUnavailableForDelivery = (error) => {
  if (error?.response?.status === 503 && error?.response?.data?.items?.length > 0) {
    return error?.response?.data?.items
      ?.filter((item) => serviceUnavailableMessages[item.code] != null)
      ?.map((item) => ({
        type: 'error',
        code: item.code,
        message: item.message,
        translation: {
          key: 'errorMessage.' + serviceUnavailableMessages[item.code] ?? item.message
        }
      }));
  }
};

const serviceUnavailableMessages = {
  INVALID_CREDENTIAL: 'invalidCredential',
  INVALID_DNS_RECORD: 'invalidDnsRecord',
  INVALID_SCHEDULE_REGISTERED: 'invalidScheduleRegistered'
};
