import { Box, Drawer, List, styled } from '@mzc-pdc/ui';
import { alpha } from '@mui/material';
import { theme } from '../../theme';

const drawerWidth = 256;

// const openedMixin = (theme: Theme): CSSObject => ({
//   top: theme.mixins.toolbar.minHeight,
//   width: drawerWidth,
//   height: `calc(100% - ${theme.mixins.toolbar.minHeight})`,
//   overflowX: 'hidden',
//   transitionDelay: '0.2s',
//   transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`
// });
//
// const closedMixin = (theme: Theme): CSSObject => ({
//   transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`,
//   top: theme.mixins.toolbar.minHeight,
//   width: theme.spacing(8)
// });

export const AppMenuDrawerHeader = styled(Box)(() => ({
  '.MuiBox-root': {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    width: 40,
    height: 22,
    padding: `5px 14px`,
    fontSize: theme.typography.body3.fontSize,
    borderRadius: `4px`,
    color: theme.palette.greySecondary[800],
    backgroundColor: theme.palette.greySecondary.contrastText,
    boxSizing: `border-box`
  }
}));
export const AppMenuDrawerBody = styled(Box)(() => ({}));

export const AppMenuDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflow: `hidden`,

    ...(open && {
      top: theme.mixins.toolbar.minHeight,
      width: drawerWidth,
      height: `calc(100% - ${theme.mixins.toolbar.minHeight})`,
      overflowX: 'hidden',
      transitionDelay: '0.2s',
      transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`
    }),
    ...(!open && {
      transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`,
      top: theme.mixins.toolbar.minHeight,
      width: theme.spacing(8)
    }),

    '& .MuiDrawer-paper': {
      color: theme.palette.greySecondary.contrastText,
      backgroundColor: theme.palette.greySecondary[800],
      ...(open && {
        top: theme.mixins.toolbar.minHeight,
        width: drawerWidth,
        height: `calc(100% - ${theme.mixins.toolbar.minHeight})`,
        overflowX: 'hidden',
        transitionDelay: '0.2s',
        transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`
      }),
      ...(!open && {
        transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`,
        top: theme.mixins.toolbar.minHeight,
        width: theme.spacing(8)
      })
    },

    '.toggle-button': {
      position: `fixed`,
      left: open ? drawerWidth && drawerWidth - 13 : 62 - 13,
      bottom: `71px`,
      zIndex: theme.zIndex.drawer + 1,
      transition: `left 300ms cubic-bezier(0.2, 0, 0, 1) 0s`
    }
  })
);

export const AppMenuList = styled(List, {
  shouldForwardProp: (prop) => !['navOpen'].includes(String(prop))
})(({ theme }) => ({
  flex: 1,
  padding: 0,
  overflowY: `auto`,
  overflowX: `hidden`,
  scrollbarGutter: `stable `,
  '&::-webkit-scrollbar': {
    width: 6,
    height: 12
  },
  '&::-webkit-scrollbar-track': {
    background: `transparent`
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `#696b6c`,
    borderRadius: 8,
    backgroundClip: `padding-box`
  },
  '.MuiListItem-root': {
    width: `auto`,
    marginRight: -6,
    padding: `4px 12px`,
    display: `block`,
    '> a': {
      color: `inherit`,
      textDecoration: `none`
    },
    '.MuiList-root': {
      marginTop: 8,
      overflow: `visible`,
      '.MuiListItem-root': {
        padding: 0,
        marginRight: 0,
        '.MuiListItemButton-root': {
          '&.Mui-selected': {
            '.MuiListItemIcon-root ': {
              color: theme.palette.primary.contrastText
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary[300], 0.7)
            }
          }
        }
      }
    },
    '&.MuiListItem-divider': {
      border: 0,
      '&:after': {
        content: '""',
        display: `block`,
        width: `100%`,
        height: 1,
        margin: `16px 0 12px`,
        backgroundColor: alpha(theme.palette.divider, 0.12)
      }
    },
    '.MuiListItemButton-root': {
      minWidth: 40,
      height: 40,
      borderRadius: 3,
      paddingLeft: 12,
      paddingRight: 12,
      '.MuiListItemIcon-root ': {
        minWidth: 16,
        alignItems: `center`,
        justifyContent: `center`,
        marginRight: 12,
        color: theme.palette.greySecondary.contrastText,
        '.MuiSvgIcon-root': {
          fontSize: 16
        }
      },
      '.MuiListItemText-root': {
        overflow: `hidden`
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.greySecondary[50], 0.12)
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary[300], 0.5),
        '.MuiListItemIcon-root ': {
          color: theme.palette.primary.contrastText
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary[300], 0.7)
        }
      }
    }
  },
  '.MuiCollapse-root': {
    '.MuiList-root': {
      marginTop: 8,
      overflow: `visible`,
      '.MuiListItem-root': {
        padding: 0,
        marginRight: 0,
        '.MuiListItemButton-root': {
          '.MuiListItemIcon-root ': {
            opacity: 0.2,
            '.MuiSvgIcon-root': {
              fontSize: 6
            }
          },
          '&.Mui-selected': {
            backgroundColor: `transparent`,
            '.MuiListItemIcon-root ': {
              opacity: 1,
              color: theme.palette.primary.main
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary[300], 0.5)
            }
          }
        }
      }
    }
  },
  '.MuiListSubheader-root': {
    position: `static`,
    display: `flex`,
    alignItems: `center`,
    minHeight: 16,
    marginBottom: 8,
    paddingLeft: 14,
    backgroundColor: `transparent`,
    lineHeight: `initial`,
    fontSize: theme.typography.body4.fontSize,
    fontWeight: `bold`,
    color: theme.palette.greySecondary[400]
  }
}));
