import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';
import useCompareJobViewModelBuilder from '@/hooks/domainBuilder/useCompareJobViewModelBuilder';

export const createKeyForGetCompareJob = ({ spaceId, projectId, jobId }) => [
  `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}`
];
export const useQueryToGetCompareJobV4 = (
  { spaceId, projectId, jobId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) => {
  const builder = useCompareJobViewModelBuilder({ spaceId, projectId, jobId });
  return useQuery(
    createKeyForGetCompareJob({ spaceId, projectId, jobId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV4({
          method: 'get',
          url: `/compare-jobs/${jobId}`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return builder(response.data);
      }, exceptionHandler),
    { ...options }
  );
};
