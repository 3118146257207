import React, { lazy } from 'react';
import { detect } from 'detect-browser';
import config from '@/config';
import WithRouteGuard from '@/routes/WithRouteGuard';
import menuService from '@/services/menuService';

const Login = lazy(() => import('@/routes/Login'));
const InvalidUser = lazy(() => import('@/routes/errors/InvalidUser'));
const NotSupportBrowser = lazy(() => import('@/routes/errors/NotSupportBrowser'));

const Welcome = lazy(() => import('@/routes/welcome/Welcome'));

const Stage = lazy(() => import('@/routes/Stage'));

const OriginBrowser = lazy(() => import('@/routes/console/OriginBrowser'));
const CdnMetrics = lazy(() => import('@/routes/console/CdnMetrics'));
const CdnMonitoring = lazy(() => import('@/routes/console/CdnMonitoring'));
const RealtimeMonitoring = lazy(() => import('@/routes/console/RealtimeMonitoringProxy'));

//project manager
const Summary = lazy(() => import('@/routes/setting/Summary'));
const UserList = lazy(() => import('@/routes/setting/Users'));
const UserDetail = lazy(() => import('@/routes/setting/User'));
const Notifications = lazy(() => import('@/routes/setting/Notifications'));
const Notification = lazy(() => import('@/routes/setting/Notification'));
const StorageDetail = lazy(() => import('@/routes/setting/Storage'));
const DnsDetail = lazy(() => import('@/routes/setting/Dns'));
const EndpointDetail = lazy(() => import('@/routes/setting/Endpoint'));
const CdnList = lazy(() => import('@/routes/setting/Cdns'));
const CdnDetail = lazy(() => import('@/routes/setting/Cdn'));
const RoutingPolicyList = lazy(() => import('@/routes/setting/RoutingPolicies'));
const RoutingPolicyDetailProxy = lazy(() => import('@/routes/setting/RoutingPolicyProxy'));
const CompareJob = lazy(() => import('@/routes/setting/CompareJobs'));
const PurgeJob = lazy(() => import('@/routes/setting/PurgeJobs'));
const DnsZoneJobs = lazy(() => import('@/routes/setting/DnsZoneJobs'));
const CompareJobDetail = lazy(() => import('@/routes/setting/CompareJob'));
const PurgeJobDetail = lazy(() => import('@/routes/setting/PurgeJob'));
const DnsZoneJob = lazy(() => import('@/routes/setting/DnsZoneJob'));

const browser = detect();

const getBrowserName = (browser) => {
  const composer = _.pipe(
    _.getOr('unknown browser', 'name'),
    _.cond([
      [_.equals('ie'), () => 'Internet Explorer'],
      [_.T, _.identity]
    ])
  );
  return composer(browser);
};

export const getPublicRoutes = () => [
  {
    name: 'welcome',
    path: menuService.rules.welcome(),
    element: <Welcome />
  },
  {
    name: 'login',
    path: config.pops.redirectUrl,
    element: <Login />
  },
  {
    name: 'login',
    path: config.pops.initiateLoginUrl,
    element: <Login />
  },
  {
    name: 'invalid-user',
    path: menuService.rules.invalidUser(),
    element: <InvalidUser />
  },
  {
    name: 'notSupportBrowser',
    path: menuService.rules.browserNotSupported(),
    element: <NotSupportBrowser browserName={getBrowserName(browser)} />
  }
];

export const getAuthRoutes = () => [
  {
    name: 'Home',
    path: menuService.rules.home(),
    element: (
      <WithRouteGuard>
        <Stage />
      </WithRouteGuard>
    )
  },
  {
    name: 'Spaces',
    path: menuService.rules.spaces(),
    element: (
      <WithRouteGuard>
        <Stage />
      </WithRouteGuard>
    )
  },
  {
    name: 'Project home',
    path: menuService.rules.projectHome(),
    element: (
      <WithRouteGuard>
        <Summary />
      </WithRouteGuard>
    )
  },
  {
    name: 'Summary',
    path: menuService.rules.summary(),
    element: (
      <WithRouteGuard>
        <Summary />
      </WithRouteGuard>
    )
  },
  {
    name: 'Project users',
    path: menuService.rules.projectUsers(),
    element: (
      <WithRouteGuard>
        <UserList />
      </WithRouteGuard>
    )
  },
  {
    name: 'Project user Detail',
    path: menuService.rules.projectUser(),
    element: (
      <WithRouteGuard>
        <UserDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'Notifications',
    path: menuService.rules.notifications(),
    element: (
      <WithRouteGuard>
        <Notifications />
      </WithRouteGuard>
    )
  },
  {
    name: 'Notification detail',
    path: menuService.rules.notification(),
    element: (
      <WithRouteGuard>
        <Notification />
      </WithRouteGuard>
    )
  },
  {
    name: 'Storage detail',
    path: menuService.rules.storage(),
    element: (
      <WithRouteGuard>
        <StorageDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'DNS detail',
    path: menuService.rules.dns(),
    element: (
      <WithRouteGuard>
        <DnsDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'Project endpoint',
    path: menuService.rules.endpoint(),
    element: (
      <WithRouteGuard>
        <EndpointDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'CDNs',
    path: menuService.rules.cdns(),
    element: (
      <WithRouteGuard>
        <CdnList />
      </WithRouteGuard>
    )
  },
  {
    name: 'CDN detail',
    path: menuService.rules.cdn(),
    element: (
      <WithRouteGuard>
        <CdnDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'Routing policies',
    path: menuService.rules.routingPolicies(),
    element: (
      <WithRouteGuard>
        <RoutingPolicyList />
      </WithRouteGuard>
    )
  },
  {
    name: 'Routing policy detail',
    path: menuService.rules.routingPolicy(),
    element: (
      <WithRouteGuard>
        <RoutingPolicyDetailProxy />
      </WithRouteGuard>
    )
  },
  {
    name: 'Compare jobs',
    path: menuService.rules.compareJobs(),
    element: (
      <WithRouteGuard>
        <CompareJob />
      </WithRouteGuard>
    )
  },

  {
    name: 'Purge jobs',
    path: menuService.rules.purgeJobs(),
    element: (
      <WithRouteGuard>
        <PurgeJob />
      </WithRouteGuard>
    )
  },
  {
    name: 'DNS Zone Record Jobs',
    path: menuService.rules.recordJobs(),
    element: (
      <WithRouteGuard>
        <DnsZoneJobs />
      </WithRouteGuard>
    )
  },
  {
    name: 'Purge job detail',
    path: menuService.rules.purgeJob(),
    element: (
      <WithRouteGuard>
        <PurgeJobDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'Compare job detail',
    path: menuService.rules.compareJob(),
    element: (
      <WithRouteGuard>
        <CompareJobDetail />
      </WithRouteGuard>
    )
  },
  {
    name: 'DNS Zone Record job Detail',
    path: menuService.rules.recordJob(),
    element: (
      <WithRouteGuard>
        <DnsZoneJob />
      </WithRouteGuard>
    )
  },
  {
    name: 'Origin Storage',
    path: menuService.rules.originStorage(),
    element: (
      <WithRouteGuard>
        <OriginBrowser />
      </WithRouteGuard>
    )
  },
  {
    name: 'CDN Metrics',
    path: menuService.rules.cdnMetrics(),
    element: (
      <WithRouteGuard>
        <CdnMetrics />
      </WithRouteGuard>
    )
  },
  {
    name: 'CDN Monitoring',
    path: menuService.rules.cdnMonitoring(),
    element: (
      <WithRouteGuard>
        <CdnMonitoring />
      </WithRouteGuard>
    )
  },
  {
    name: 'Real-time Monitoring',
    path: menuService.rules.realtimeMonitoring(),
    element: (
      <WithRouteGuard>
        <RealtimeMonitoring />
      </WithRouteGuard>
    )
  }
];

const routes = [...getPublicRoutes(), ...getAuthRoutes()];
export default routes;
