import React from 'react';
import { ReactNode } from 'react';

interface LoadableContainerProps {
  loading: boolean;
  loader: ReactNode;
  children: ReactNode;
}

export const LoadableContainer = ({ loading, loader, children }: LoadableContainerProps) => {
  if (loading) return <>{loader}</>;
  return <>{children}</>;
};
