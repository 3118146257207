import {handleActions} from 'redux-actions';

const UPDATE_DEFAULT = 'routingPolicyForm/UPDATE_DEFAULT';
const UPDATE_CONTINENT = 'routingPolicyForm/UPDATE_CONTINENT';
const UPDATE_COUNTRY = 'routingPolicyForm/UPDATE_COUNTRY';
const EDIT_CONTINENT = 'routingPolicyForm/EDIT_CONTINENT';
const EDIT_COUNTRY = 'routingPolicyForm/EDIT_COUNTRY';

const initialState = {
  default: [],
  continents: [],
  countries: []
};

export default handleActions(
  {
    [UPDATE_DEFAULT]: (state, {payload}) => ({
      ...state,
      default: payload
    }),

    [UPDATE_CONTINENT]: (state, {payload}) => {
      let temp = _.cloneDeep(state.continents);
      let diffIndex = temp.findIndex((t) => t.code === payload.code);
      let continents;
      if (diffIndex > -1) {
        temp.splice(diffIndex, 1, payload);
        continents = temp;
      } else {
        continents = temp.concat([payload]);
      }
      return {
        ...state,
        continents: continents
      };
    },

    [UPDATE_COUNTRY]: (state, {payload}) => {
      let temp = _.cloneDeep(state.countries);
      let diffIndex = temp.findIndex((t) => t.code === payload.code);
      let countries;
      if (diffIndex > -1) {
        temp.splice(diffIndex, 1, payload);
        countries = temp;
      } else {
        countries = temp.concat([payload]);
      }
      return {
        ...state,
        countries: countries
      };
    },

    [EDIT_CONTINENT]: (state, {payload}) => ({
      ...state,
      continents: payload
    }),

    [EDIT_COUNTRY]: (state, {payload}) => ({
      ...state,
      countries: payload
    })
  },
  initialState
);

export const updateGeoCustomDefault = (list) => (dispatch) => {
  dispatch({
    type: UPDATE_DEFAULT,
    payload: list
  });
};

export const updateGeoCustomContinent = (list) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTINENT,
    payload: list
  });
};
export const updateGeoCustomCountry = (list) => (dispatch) => {
  dispatch({
    type: UPDATE_COUNTRY,
    payload: list
  });
};

export const editContinent = (list) => (dispatch) => {
  dispatch({
    type: EDIT_CONTINENT,
    payload: list
  });
};

export const editCountry = (list) => (dispatch) => {
  dispatch({
    type: EDIT_COUNTRY,
    payload: list
  });
};
