import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@packages/legacy-ui';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@packages/legacy-ui';
import { Grid, Box, Chip, Autocomplete, Typography } from '@mui/material';
import ListTool from '@/components/common/ListTool';
import {
  createKeyForListCompareJobTasks,
  useQueryToListCompareJobTasks
} from '@/hooks/queries/useQueryToListCompareJobTasks';
import CompareJobTaskItem from '@/components/manage/job/compareApiV4/CompareJobTaskItem';
import TextField from '@mui/material/TextField';
import { LoadableContainer } from '@/components/common/containers/LoadableContainer';
import { useQueryClient } from 'react-query';
import TaskItemSkeleton from '@/components/manage/job/common/TaskItemSkeleton';

const CompareJobTaskList = ({ spaceId, job, onItemClick, onChangeTask }) => {
  const { t } = useTranslation('jobs');
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState([]);

  const { data: dataToListCompareJobTasks, isLoading } = useQueryToListCompareJobTasks(
    { spaceId: spaceId, projectId: job.project.id, jobId: job.id },
    { page: page - 1, size: pageSize, statuses: statusFilter }
  );

  const getStatusOptions = [
    { value: 'IDENTICAL', label: t('identical') },
    { value: 'DIFFERENT', label: t('different') },
    { value: 'FAILED', label: t('failed') },
    { value: 'IN_PROGRESS', label: t('inProgress') }
  ];

  const handleStatusChange = (_, selectedOptions) => {
    setStatusFilter(selectedOptions.map((option) => option.value));
  };

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs>
          <Autocomplete
            multiple
            disableCloseOnSelect
            value={getStatusOptions.filter((option) => statusFilter.includes(option.value))}
            options={getStatusOptions}
            getOptionLabel={(option) => `${option.label}`}
            onChange={handleStatusChange}
            renderTags={(tags) =>
              tags.length > 0 ? (
                <>
                  <Typography style={{ marginLeft: '10px' }}>{tags[0]?.label ?? ''}</Typography>
                  {tags.length > 1 && (
                    <Chip style={{ height: 'auto' }} label={`+${tags.length - 1}`} />
                  )}
                </>
              ) : null
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox color={'default'} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                variant='outlined'
                placeholder={t('selectStatus')}
              />
            )}
            style={{ width: 230 }}
          />
        </Grid>
        <Grid item xs>
          <ListTool
            divider={false}
            totalCount={dataToListCompareJobTasks?.totalCount}
            page={page}
            pageSize={pageSize}
            onRefresh={async () => {
              await queryClient.invalidateQueries(
                createKeyForListCompareJobTasks(
                  { spaceId: spaceId, projectId: job.project.id, jobId: job.id },
                  { page: page - 1, size: pageSize }
                )
              );
            }}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        id='table-container'
        sx={{ flex: `1`, overflow: `auto`, border: `none`, minHeight: 400 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell minWidth={600}>{t('path')}</TableCell>
              <TableCell minWidth={300}>CDN</TableCell>
              <TableCell minWidth={180}>{t('result')}</TableCell>
              <TableCell minWidth={180}>{t('startedAt')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <LoadableContainer
              loading={isLoading}
              loader={Array.from({ length: pageSize }, (_, index) => (
                <TaskItemSkeleton key={index} />
              ))}
            >
              {!isLoading && dataToListCompareJobTasks?.results.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={4} align={'center'}>
                    <Box sx={{ py: 3 }}>{t('message.noTasks')}</Box>
                  </TableCell>
                </TableRow>
              ) : (
                dataToListCompareJobTasks?.results.map((item) => (
                  <CompareJobTaskItem
                    key={item.id}
                    spaceId={spaceId}
                    projectId={job.project.id}
                    jobId={job.id}
                    taskId={item.id}
                    cdn={item.cdn}
                    filePath={item.filePath}
                    onItemClick={onItemClick}
                    onChangeTask={onChangeTask}
                  />
                ))
              )}
            </LoadableContainer>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompareJobTaskList;
