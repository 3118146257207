import React from 'react';
import { Box, Typography } from '@mzc-pdc/ui';
import { IconOpenInNewSmall } from '@mzc-cloudplex/icons';
import { Link, LinkProps } from '../links/Link';

export const WithIdRow = (props: { id: string; children: React.ReactNode } & LinkProps) => (
  <Link to={props.to} target={props.target}>
    <Box display={'inline-flex'} alignItems={'center'} gap={0.5}>
      <Typography noWrap overflow={'hidden'} textOverflow={'ellipsis'}>
        {props.children}
        {props.to && <IconOpenInNewSmall size={16} />}
      </Typography>
      <Typography variant={'body3'} color={'text.secondary'}>
        ({props.id})
      </Typography>
    </Box>
  </Link>
);
