import { toastTypesDic, useToast } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { forbidden, uncaught } from '@/services/infrastructure/exceptionAnalyzers/common';
import {
  badRequestForDelivery,
  conflictForDelivery,
  notFoundForDelivery,
  serviceUnavailableForDelivery
} from '@/services/infrastructure/exceptionAnalyzers/delivery';
import { CanceledError } from 'axios';

export const exceptionProcessorForMutation = () => {
  const { toast } = useToast();
  const { t } = useTranslation('common');

  return {
    handle: (error) => {
      if (error instanceof CanceledError) return false;

      if (error?.response?.status === 401) return true;

      const processors = [
        forbidden,
        badRequestForDelivery,
        notFoundForDelivery,
        conflictForDelivery,
        serviceUnavailableForDelivery,
        uncaught
      ];
      for (let processor of processors) {
        const messages = processor(error);
        if (messages?.length > 0) {
          messages.forEach((message) => {
            toast(t(message.translation.key, { property: t(message.translation.property) }), {
              type: toastTypesDic.error,
              onClose: () => {}
            });
          });
          return true;
        }
      }
    }
  };
};
