import { combineReducers } from 'redux';
import rightSideBar from '@/store/rightSideBar';
import job from '@/store/job';

import project from '@/store/project';
import routingPolicyForm from '@/store/routingPolicyForm';

const rootReducer = combineReducers({
  project,
  routingPolicyForm,
  job,
  rightSideBar,
});

export default rootReducer;
