import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import Cookies from 'js-cookie';
import { SESSION_STORED_KEY } from '@/constants';
import AppDownloadModal from '@/components/common/AppDownload';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';

import { AppBar, IconButton, Grid, Divider, MenuItem, Typography } from '@mui/material';
import {
  IconApps,
  IconAppDownload,
  ImageLogoCloudplexDelivery,
  ImageMyjobsOff
} from '@mzc-cloudplex/icons';
import { Toolbar, Menu } from '@/components/layouts/header/header.styled';
import theme from '@/styles/theme';
import ProfileMenu from '@/components/layouts/header/ProfileMenu';
import menuService from '@/services/menuService';
import { useQueryToGetMyInfo } from '@/hooks/queries/useQueryToGetMyInfo';
import Lottie from 'lottie-react';
import loadingLottie from '@/assets/lottie/my-jobs-loading.json';
import MyEventObserver from '@/contexts/MyEventObserver';
import { JOB_EVENTS } from '@/hooks/useEventObserver';

const Header = ({ openJobPanel, hasNewEvent, newEventOn }) => {
  const { t } = useTranslation();
  const { subscribe, unsubscribe } = useContext(MyEventObserver.context);
  const { data: dataToGetMyInfo } = useQueryToGetMyInfo();

  const [showSettingLayer, setShowSettingLayer] = useState(null);
  const settingLayerOpen = Boolean(showSettingLayer);
  const [showAppDownloadModal, hideAppDownloadModal] = useModal(() => (
    <AppDownloadModal onClickClose={hideAppDownloadModal} />
  ));
  const { projectId } = useDeliveryLocation();

  const onClickShowOtherMenu = useCallback(
    (e) => setShowSettingLayer(e.currentTarget),
    [showSettingLayer]
  );

  const onClickCloseOtherMenu = useCallback(() => setShowSettingLayer(null), [showSettingLayer]);

  useEffect(() => {
    Cookies.set(
      SESSION_STORED_KEY,
      JSON.stringify({
        projectId: projectId
      })
    );
  }, [projectId]);

  useEffect(() => {
    const callback = () => newEventOn();
    subscribe(JOB_EVENTS.PURGE_JOB, callback);
    subscribe(JOB_EVENTS.COMPARE_JOB, callback);
    return () => {
      unsubscribe(JOB_EVENTS.PURGE_JOB, callback);
      unsubscribe(JOB_EVENTS.COMPARE_JOB, callback);
    };
  });

  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: 'common.white',
          color: theme.palette.secondary.main,
          boxShadow: `none`,
          borderBottom: `1px solid ${theme.palette.divider}`
        }}
      >
        <Toolbar sx={{ p: 0 }}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item sx={{ padding: `10px 9px 10px 10px` }}>
              <IconButton
                id='other-menu'
                size={'large'}
                aria-controls={settingLayerOpen ? 'other-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={settingLayerOpen ? 'true' : undefined}
                onClick={onClickShowOtherMenu}
              >
                <IconApps size={16} />
              </IconButton>
              <Menu
                id='other-menu'
                anchorEl={showSettingLayer}
                open={settingLayerOpen}
                onClose={onClickCloseOtherMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem
                  onClick={() => {
                    onClickCloseOtherMenu();
                    showAppDownloadModal();
                  }}
                >
                  <IconAppDownload size={16} />
                  <Typography fontWeight={500}>{t('label.downloadApps')}</Typography>
                </MenuItem>
              </Menu>
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid item xs pl={'16px'}>
              <h1>
                <Link to={menuService.paths.home()}>
                  <ImageLogoCloudplexDelivery width={218} height={28} />
                </Link>
              </h1>
            </Grid>
            <Grid item>
              <IconButton size={'medium'} onClick={openJobPanel}>
                {hasNewEvent ? (
                  <Lottie
                    animationData={loadingLottie}
                    style={{ height: '24px', width: '24px', margin: 'auto' }}
                  />
                ) : (
                  <ImageMyjobsOff width={24} height={24} />
                )}
              </IconButton>
            </Grid>
            <Grid item pl={1.5} pr={1.5}>
              <ProfileMenu user={dataToGetMyInfo} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
