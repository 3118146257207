import React from 'react';
import { INFORMATION_CONTENT_WIDTH } from '@/components/layouts/informationPanel/styles/informationPanel.styled';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RealtimeMonitoringInfo = () => {
  const { t } = useTranslation('routingPolicy');
  const usageList = [
    'usage1',
    'usage2',
    'usage3',
  ];
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '8px',
        width: `${INFORMATION_CONTENT_WIDTH}px`
      }}
    >
      <Typography>{t(`info.header`)}</Typography>
      <Typography variant={'h6'}>{t(`common::label.usage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {usageList.map((usage) => (
          <ListItem key={usage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`info.realtimeMonitoring.${usage}`)}</Typography>
          </ListItem>
        ))}
      </List>

    </Grid>
  );
};
export default RealtimeMonitoringInfo;
