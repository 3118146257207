import { useCallback, useEffect, useRef, useState } from 'react';
import config from '@/config';
import { ApiFactory } from '@/utils/apiFactory';

export const JOB_EVENTS = {
  PURGE_JOB: 'purge-job',
  COMPARE_JOB: 'compare-job',
  RECORD_JOB: 'record-job'
};

const useEventObserver = ({ prefix, topic }) => {
  const eventSubscriptionsRef = useRef({});
  const [isConnected, setConnected] = useState(false);

  const eventCodeValidator = (code) => Object.values(JOB_EVENTS).includes(code);

  const handleError = (eventSource, error) => {
    console.error(eventSource, 'project sse connection error: ', error);
    if (eventSource.readyState === EventSource.CLOSED) setConnected(false);
  };

  const handleMessage = useCallback((subscriptionRef, eventData) => {
    const { code, body } = eventData;

    subscriptionRef.current[code]?.forEach((callback) => callback(body));
  }, []);

  const subscribe = useCallback((eventCode, callback) => {
    if (eventSubscriptionsRef.current[eventCode] == null)
      eventSubscriptionsRef.current[eventCode] = new Set();

    eventSubscriptionsRef.current[eventCode].add(callback);
  }, []);

  const unsubscribe = useCallback((eventCode, callback) => {
    if (eventSubscriptionsRef.current[eventCode] == null) return;

    eventSubscriptionsRef.current[eventCode].delete(callback);
  }, []);

  useEffect(() => {
    if (isConnected) return;

    const connect = (token) => {
      if (topic == null || token == null) return;
      const url = `${config.cpd.channelV1}/receive?divisions[]=${prefix}${topic}&token=${token}`;
      const eventSource = new EventSource(url);
      eventSource.onopen = () => setConnected(true);
      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        if (eventCodeValidator(eventData.code)) handleMessage(eventSubscriptionsRef, eventData);
      };
      eventSource.onerror = (error) => handleError(eventSource, error);
      return eventSource;
    };

    const getChannelToken = async (signal) => {
      const response = await ApiFactory.cpdChannelV1({
        method: 'post',
        url: '/token',
        signal: signal
      });
      return response.data;
    };
    const controller = new AbortController();
    getChannelToken(controller.signal)
      .then((response) => connect(response.token))
      .catch((err) => console.error('error getting channel token, ', err));
    return () => controller.abort();
  }, [isConnected, prefix, topic]);

  return { subscribe, unsubscribe };
};

export default useEventObserver;
