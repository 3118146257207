import {Avatar as MuiAvatar, MenuItem as MuiMenuItem, styled} from "@mui/material";


export const ProfileMenuItem = styled(MuiMenuItem)(() => ({
  display: `flex`,
  textAlign: `left`,
  '.MzcCpIcon-root': {
    marginLeft: `8px`
  }
}));

export const Name = styled('p')(({ theme }) => ({
  fontSize: `14px`,
  letterSpacing: `-0.24px`,
  fontWeight: `500`,
  color: theme.palette.secondary[800],
  textOverflow: `ellipsis`,
  whitespace: `nowrap`,
  overflow: `hidden`
}));

export const Email = styled('p')(({ theme }) => ({
  margin: `0`,
  width: `100%`,
  fontSize: `12px`,
  letterSpacing: `-0.2px`,
  color: theme.palette.secondary[600]
}));

export const ProfileItem = styled(MuiMenuItem)(() => ({
  cursor: `default`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `12px 20px 8px 20px`,
  textAlign: `left`,
  '&:hover': {
    backgroundColor: `transparent`
  },
  '&:active': {
    backgroundColor: `transparent`
  },
  '.MuiTouchRippleRoot': {
    visibility: `hidden`
  }
}));

export const Avatar = styled(MuiAvatar)``;
