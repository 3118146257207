import React from 'react';
import { FormControlLabel, Radio } from '@mzc-pdc/ui';

interface RadioItemProps<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export const RadioItem = <T,>(props: RadioItemProps<T>) => (
  <FormControlLabel
    control={<Radio color={'default'} />}
    disabled={props.disabled}
    label={props.label}
    value={props.value}
    componentsProps={{ typography: { fontSize: '14px' } }}
  />
);
