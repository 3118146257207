import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetCompareJob = ({ spaceId, projectId, jobId }) => [
  `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}`
];
export const useQueryToGetCompareJobV3 = (
  { spaceId, projectId, jobId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    [...createKeyForGetCompareJob({ spaceId, projectId, jobId }), 'V3'],
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/compare-jobs/${jobId}`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
