import React from 'react';
import { StyledStatus } from '@/components/common/statuses/status.styled';
import { IconCheck } from '@mzc-cloudplex/icons';
import { UPLOAD_STATUS } from '@/utils/upload';
import { useTranslation } from 'react-i18next';

const UploadStatus = ({ status }) => {
  const { t } = useTranslation('jobs');
  return (
    <>
      {status === UPLOAD_STATUS.PREPARED && (
        <StyledStatus
          label={t('prepared')}
        />
      )}
      {status === UPLOAD_STATUS.COMPLETED && (
        <StyledStatus
          label={t('completed')}
          color={'success'}
          icon={<IconCheck size={12} htmlColor={'#fff'} />}
        />
      )}
    </>
  );
};


export default UploadStatus;
