import { ApiFactory } from '@/utils/apiFactory';

export const listObjects = async (
  { spaceId, projectId, bucketName, region, prefix = '' },
  abortController
) => {
  try {
    const path = prefix
      ? `&prefix=${encodeURIComponent(prefix)
          .replace(/!/gi, '%21')
          .replace(/'/gi, '%27')
          .replace(/\(/gi, '%28')
          .replace(/\)/gi, '%29')}`
      : '';
    const result = await ApiFactory.cpdV3({
      method: 'post',
      url: `/aws-proxy:request`,
      headers: {
        'cloudplex-space-id': spaceId,
        'cloudplex-project-id': projectId
      },
      data: {
        service: 's3',
        method: 'GET',
        region: region,
        uri: `https://${bucketName}.s3.amazonaws.com/?Action=GetObject&Version=2006-03-01${path}`,
        data: '',
        data_binary: false
      },
      signal: abortController?.signal
    });

    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const headObject = async (
  { spaceId, projectId, bucketName, region, fileName },
  abortController
) => {
  const file = `${encodeURIComponent(fileName)
    .replace(/!/gi, '%21')
    .replace(/'/gi, '%27')
    .replace(/\(/gi, '%28')
    .replace(/\)/gi, '%29')}`;

  const result = await ApiFactory.cpdV3({
    method: 'post',
    url: `/aws-proxy:request`,
    headers: {
      'cloudplex-space-id': spaceId,
      'cloudplex-project-id': projectId
    },
    data: {
      service: 's3',
      method: 'HEAD',
      region: region,
      uri: `https://${bucketName}.s3.amazonaws.com/${file}?Action=HeadObject&Version=2006-03-01`,
      data: '',
      data_binary: false
    },
    signal: abortController?.signal
  });
  return result?.data['status code'] === 200;
};
