import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';
import { createQueryKey } from '@/utils/createQueryKey';

export const createKeyForListMyCompareJobs = ({ spaceId }, params) =>
  createQueryKey([`/spaces/${spaceId}/my-compare-jobs`], params);

export const useQueryToListMyCompareJobsV4 = (
  { spaceId },
  { page, size },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListMyCompareJobs({ spaceId }, { page, size }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV4({
          method: 'get',
          url: `/users/me/compare-jobs`,
          headers: {
            'cloudplex-space-id': spaceId
          },
          params: {
            page: page,
            size: size
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
