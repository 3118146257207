import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetMyInfoInProject = ({ spaceId, projectId }) => [
  `/spaces/${spaceId}/projects/${projectId}/projects-users/me`
];

export const useQueryToGetMyInfoInProject = (
  { spaceId, projectId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetMyInfoInProject({ spaceId, projectId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/project-users/me`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
