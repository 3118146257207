import { AREA_TYPES } from '@/services/areas';
import { CDN_SERVICES } from '@/services/cdn/cdnService';

export const VIEW_MODE = {
  TREE_VIEW: 'treeView',
  ZONE_FILE: 'zoneFileView'
};

export const ZONE_RECORD_TYPE = {
  NS: 'NS',
  SOA: 'SOA'
};

export const RECORD_JOB_STATUS = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};

export const ROUTING_TYPE = {
  SIMPLE: 'SIMPLE',
  WEIGHTED: 'WEIGHTED',
  GEOLOCATION: 'GEOLOCATION',
  FAILOVER: 'FAILOVER'
};

const createHierarchyRecords = (records) => {
  const childRecordNames = new Set();
  const nameAndIndexes = new Map();
  const rootRecord = {
    source: null,
    children: []
  };
  const setChildren = (parent, indexes) => {
    indexes.forEach((recordIndex) => {
      const record = {
        source: records[recordIndex],
        children: []
      };
      const targetValue = record.source.value;
      parent.children.push(record);
      if (nameAndIndexes.has(targetValue)) {
        setChildren(record, nameAndIndexes.get(targetValue));
      }
    });
  };
  for (let index = 0; index < records.length; index++) {
    const recordName = records[index].name;
    childRecordNames.add(records[index].value);
    nameAndIndexes.has(recordName)
      ? nameAndIndexes.get(recordName).push(index)
      : nameAndIndexes.set(recordName, [index]);
  }
  nameAndIndexes.forEach((indexes, name) => {
    if (childRecordNames.has(name)) {
      return;
    }
    setChildren(rootRecord, indexes);
  });
  childRecordNames.clear();
  nameAndIndexes.clear();
  return rootRecord.children;
};

const groupByParentName = (originalArray) =>
  Object.entries(
    originalArray.reduce((groupByName, record) => {
      const { name, routingPolicy, trafficRoutingPolicy } = record.source;
      if (!groupByName[name])
        groupByName[name] = {
          name,
          routingPolicy,
          routingType: trafficRoutingPolicy.type,
          children: []
        };
      groupByName[name].children.push(record);

      return groupByName;
    }, {})
  ).map(([, value]) =>
    value.routingType === ROUTING_TYPE.GEOLOCATION
      ? {
          ...value,
          children: value.children.sort((a, b) => {
            if (a.source.trafficRoutingPolicy.codeType === AREA_TYPES.DEFAULT) return -1;
            else if (b.source.trafficRoutingPolicy.codeType === AREA_TYPES.DEFAULT) return 1;
            else return 0;
          })
        }
      : value
  );

export const dnsRecordService = {
  createDnsRecordHierarchy: (records) => {
    if (records == null) return;
    const hierarchyRecords = createHierarchyRecords(records);
    const ingestRecord = hierarchyRecords.filter((record) => record.source.name.startsWith('in.'));
    const nsRecords = hierarchyRecords.filter(
      (record) => record.source.type === ZONE_RECORD_TYPE.NS
    );
    const soaRecords = hierarchyRecords.filter(
      (record) => record.source.type === ZONE_RECORD_TYPE.SOA
    );
    return {
      nsRecords: groupByParentName(nsRecords),
      ingestRecord:
        ingestRecord.length > 0
          ? {
              ingestRecordName: ingestRecord[0]?.source.name,
              appliedPolicy: groupByParentName(ingestRecord[0]?.children)
            }
          : null,
      soaRecords: groupByParentName(soaRecords),
      routingPolicyRecords: groupByParentName(
        hierarchyRecords.filter(
          (record) =>
            !(
              ingestRecord.includes(record) ||
              nsRecords.includes(record) ||
              soaRecords.includes(record)
            )
        )
      )
    };
  },
  getCdnService: (recordName, soaRecord) => {
    const prefix = recordName.substring(0, recordName.lastIndexOf(soaRecord));
    const result = prefix.endsWith('.') ? prefix.slice(0, -1) : prefix;
    const split = result.split('.');
    return split[split.length - 1].substring(0, 2);
  },
  getCustomCdnByCname: (cdn, record, recordType) =>
    cdn.results.find(
      (cdnItem) =>
        cdnItem.service === CDN_SERVICES.CUSTOM &&
        cdnItem.record === record &&
        cdnItem.recordType === recordType
    )
};
