export const AREA_TYPES = {
  CONTINENT: 'CONTINENT',
  COUNTRY: 'COUNTRY',
  DEFAULT: 'DEFAULT'
};

export const areas = [
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'AF',
    label: 'Africa',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'DZ', label: 'Algeria' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AO', label: 'Angola' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BJ', label: 'Benin' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BW', label: 'Botswana' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BF', label: 'Burkina Faso' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BI', label: 'Burundi' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CM', label: 'Cameroon' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CV', label: 'Cape Verde' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CF', label: 'Central African Republic' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TD', label: 'Chad' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KM', label: 'Comoros' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CG', label: 'Congo' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CI', label: "Cote D'ivoire" },
      { codeType: AREA_TYPES.COUNTRY, code: 'DJ', label: 'Djibouti' },
      { codeType: AREA_TYPES.COUNTRY, code: 'EG', label: 'Egypt' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GQ', label: 'Equatorial Guinea' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ER', label: 'Eritrea' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ET', label: 'Ethiopia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GA', label: 'Gabon' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GM', label: 'Gambia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GH', label: 'Ghana' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GN', label: 'Guinea' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GW', label: 'Guinea-bissau' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KE', label: 'Kenya' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LS', label: 'Lesotho' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LR', label: 'Liberia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LY', label: 'Libya' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MG', label: 'Madagascar' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MW', label: 'Malawi' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ML', label: 'Mali' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MR', label: 'Mauritania' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MU', label: 'Mauritius' },
      { codeType: AREA_TYPES.COUNTRY, code: 'YT', label: 'Mayotte' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MA', label: 'Morocco' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MZ', label: 'Mozambique' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NA', label: 'Namibia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NE', label: 'Niger' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NG', label: 'Nigeria' },
      { codeType: AREA_TYPES.COUNTRY, code: 'RE', label: 'Reunion' },
      { codeType: AREA_TYPES.COUNTRY, code: 'RW', label: 'Rwanda' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SH', label: 'Saint Helena' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ST', label: 'Sao Tome and Principe' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SN', label: 'Senegal' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SC', label: 'Seychelles' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SL', label: 'Sierra Leone' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SO', label: 'Somalia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ZA', label: 'South Africa' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SS', label: 'South Sudan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SD', label: 'Sudan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SZ', label: 'Swaziland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TZ', label: 'Tanzania, United Republic of' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CD', label: 'The Democratic Republic of The Congo' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TG', label: 'Togo' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TN', label: 'Tunisia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'UG', label: 'Uganda' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ZM', label: 'Zambia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ZW', label: 'Zimbabwe' }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'AN',
    label: 'Antarctica',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AQ', label: 'Antarctica' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TF', label: 'French Southern Territories' },
      {
        codeType: AREA_TYPES.COUNTRY,
        code: 'GS',
        label: 'South Georgia and The South Sandwich Islands'
      }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'AS',
    label: 'Asia',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AF', label: 'Afghanistan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AM', label: 'Armenia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AZ', label: 'Azerbaijan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BH', label: 'Bahrain' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BD', label: 'Bangladesh' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BT', label: 'Bhutan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IO', label: 'British Indian Ocean Territory' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BN', label: 'Brunei Darussalam' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KH', label: 'Cambodia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CN', label: 'China' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CC', label: 'Cocos (Keeling) Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KP', label: "Democratic People's Republic of Korea" },
      { codeType: AREA_TYPES.COUNTRY, code: 'GE', label: 'Georgia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'HK', label: 'Hong Kong' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IN', label: 'India' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ID', label: 'Indonesia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IR', label: 'Iran' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IQ', label: 'Iraq' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IL', label: 'Israel' },
      { codeType: AREA_TYPES.COUNTRY, code: 'JP', label: 'Japan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'JO', label: 'Jordan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KZ', label: 'Kazakhstan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KW', label: 'Kuwait' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KG', label: 'Kyrgyzstan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LA', label: "Lao People's Democratic Republic" },
      { codeType: AREA_TYPES.COUNTRY, code: 'LB', label: 'Lebanon' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MO', label: 'Macao' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MY', label: 'Malaysia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MV', label: 'Maldives' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MN', label: 'Mongolia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MM', label: 'Myanmar' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NP', label: 'Nepal' },
      { codeType: AREA_TYPES.COUNTRY, code: 'OM', label: 'Oman' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PK', label: 'Pakistan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PS', label: 'Palestine' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PH', label: 'Philippines' },
      { codeType: AREA_TYPES.COUNTRY, code: 'QA', label: 'Qatar' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KR', label: 'Republic of Korea' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SA', label: 'Saudi Arabia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SG', label: 'Singapore' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LK', label: 'Sri Lanka' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SY', label: 'Syrian Arab Republic' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TW', label: 'Taiwan, Province of China' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TJ', label: 'Tajikistan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TH', label: 'Thailand' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TM', label: 'Turkmenistan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AE', label: 'United Arab Emirates' },
      { codeType: AREA_TYPES.COUNTRY, code: 'UZ', label: 'Uzbekistan' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VN', label: 'Viet Nam' },
      { codeType: AREA_TYPES.COUNTRY, code: 'YE', label: 'Yemen' }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'EU',
    label: 'Europe',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AX', label: 'Aland Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AL', label: 'Albania' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AD', label: 'Andorra' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AT', label: 'Austria' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BY', label: 'Belarus' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BE', label: 'Belgium' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BA', label: 'Bosnia and Herzegovina' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BG', label: 'Bulgaria' },
      { codeType: AREA_TYPES.COUNTRY, code: 'HR', label: 'Croatia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CY', label: 'Cyprus' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CZ', label: 'Czech Republic' },
      { codeType: AREA_TYPES.COUNTRY, code: 'DK', label: 'Denmark' },
      { codeType: AREA_TYPES.COUNTRY, code: 'EE', label: 'Estonia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FO', label: 'Faroe Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FI', label: 'Finland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FR', label: 'France' },
      { codeType: AREA_TYPES.COUNTRY, code: 'DE', label: 'Germany' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GI', label: 'Gibraltar' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GR', label: 'Greece' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GG', label: 'Guernsey' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VA', label: 'Holy See (Vatican City State)' },
      { codeType: AREA_TYPES.COUNTRY, code: 'HU', label: 'Hungary' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IS', label: 'Iceland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IE', label: 'Ireland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IM', label: 'Isle of Man' },
      { codeType: AREA_TYPES.COUNTRY, code: 'IT', label: 'Italy' },
      { codeType: AREA_TYPES.COUNTRY, code: 'JE', label: 'Jersey' },
      { codeType: AREA_TYPES.COUNTRY, code: 'XK', label: 'Kosovo' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LV', label: 'Latvia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LI', label: 'Liechtenstein' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LT', label: 'Lithuania' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LU', label: 'Luxembourg' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MK', label: 'Macedonia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MT', label: 'Malta' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MD', label: 'Moldova' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MC', label: 'Monaco' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ME', label: 'Montenegro' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NL', label: 'Netherlands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NO', label: 'Norway' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PL', label: 'Poland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PT', label: 'Portugal' },
      { codeType: AREA_TYPES.COUNTRY, code: 'RO', label: 'Romania' },
      { codeType: AREA_TYPES.COUNTRY, code: 'RU', label: 'Russian Federation' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SM', label: 'San Marino' },
      { codeType: AREA_TYPES.COUNTRY, code: 'RS', label: 'Serbia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SK', label: 'Slovakia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SI', label: 'Slovenia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'ES', label: 'Spain' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SJ', label: 'Svalbard and Jan Mayen' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SE', label: 'Sweden' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CH', label: 'Switzerland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TR', label: 'Turkey' },
      { codeType: AREA_TYPES.COUNTRY, code: 'UA', label: 'Ukraine' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GB', label: 'United Kingdom' }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'NA',
    label: 'North America',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AI', label: 'Anguilla' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AG', label: 'Antigua and Barbuda' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AW', label: 'Aruba' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BS', label: 'Bahamas' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BB', label: 'Barbados' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BZ', label: 'Belize' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BM', label: 'Bermuda' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CA', label: 'Canada' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KY', label: 'Cayman Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CR', label: 'Costa Rica' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CU', label: 'Cuba' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CW', label: 'Curaçao' },
      { codeType: AREA_TYPES.COUNTRY, code: 'DM', label: 'Dominica' },
      { codeType: AREA_TYPES.COUNTRY, code: 'DO', label: 'Dominican Republic' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SV', label: 'El Salvador' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GL', label: 'Greenland' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GD', label: 'Grenada' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GP', label: 'Guadeloupe' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GT', label: 'Guatemala' },
      { codeType: AREA_TYPES.COUNTRY, code: 'HT', label: 'Haiti' },
      { codeType: AREA_TYPES.COUNTRY, code: 'HN', label: 'Honduras' },
      { codeType: AREA_TYPES.COUNTRY, code: 'JM', label: 'Jamaica' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MQ', label: 'Martinique' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MX', label: 'Mexico' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MS', label: 'Montserrat' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AN', label: 'Netherlands Antilles' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NI', label: 'Nicaragua' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PA', label: 'Panama' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PR', label: 'Puerto Rico' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BL', label: 'Saint Barthélemy' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KN', label: 'Saint Kitts and Nevis' },
      { codeType: AREA_TYPES.COUNTRY, code: 'LC', label: 'Saint Lucia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MF', label: 'Saint Martin' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PM', label: 'Saint Pierre and Miquelon' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VC', label: 'Saint Vincent and The Grenadines' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SX', label: 'Sint Maarten' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TT', label: 'Trinidad and Tobago' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TC', label: 'Turks and Caicos Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'US', label: 'United States' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VG', label: 'Virgin Islands, British' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VI', label: 'Virgin Islands, U.S.' }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'OC',
    label: 'Oceania',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AS', label: 'American Samoa' },
      { codeType: AREA_TYPES.COUNTRY, code: 'AU', label: 'Australia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CK', label: 'Cook Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FJ', label: 'Fiji' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PF', label: 'French Polynesia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GU', label: 'Guam' },
      { codeType: AREA_TYPES.COUNTRY, code: 'KI', label: 'Kiribati' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MH', label: 'Marshall Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FM', label: 'Micronesia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NR', label: 'Nauru' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NC', label: 'New Caledonia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NZ', label: 'New Zealand' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NU', label: 'Niue' },
      { codeType: AREA_TYPES.COUNTRY, code: 'NF', label: 'Norfolk Island' },
      { codeType: AREA_TYPES.COUNTRY, code: 'MP', label: 'Northern Mariana Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PW', label: 'Palau' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PG', label: 'Papua New Guinea' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PN', label: 'Pitcairn' },
      { codeType: AREA_TYPES.COUNTRY, code: 'WS', label: 'Samoa' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SB', label: 'Solomon Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TL', label: 'Timor-Leste' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TK', label: 'Tokelau' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TO', label: 'Tonga' },
      { codeType: AREA_TYPES.COUNTRY, code: 'TV', label: 'Tuvalu' },
      { codeType: AREA_TYPES.COUNTRY, code: 'UM', label: 'United States Minor Outlying Islands' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VU', label: 'Vanuatu' },
      { codeType: AREA_TYPES.COUNTRY, code: 'WF', label: 'Wallis and Futuna' }
    ]
  },
  {
    codeType: AREA_TYPES.CONTINENT,
    code: 'SA',
    label: 'South America',
    countries: [
      { codeType: AREA_TYPES.COUNTRY, code: 'AR', label: 'Argentina' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BO', label: 'Bolivia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'BR', label: 'Brazil' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CL', label: 'Chile' },
      { codeType: AREA_TYPES.COUNTRY, code: 'CO', label: 'Colombia' },
      { codeType: AREA_TYPES.COUNTRY, code: 'EC', label: 'Ecuador' },
      { codeType: AREA_TYPES.COUNTRY, code: 'FK', label: 'Falkland Islands (Malvinas)' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GF', label: 'French Guiana' },
      { codeType: AREA_TYPES.COUNTRY, code: 'GY', label: 'Guyana' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PY', label: 'Paraguay' },
      { codeType: AREA_TYPES.COUNTRY, code: 'PE', label: 'Peru' },
      { codeType: AREA_TYPES.COUNTRY, code: 'SR', label: 'Suriname' },
      { codeType: AREA_TYPES.COUNTRY, code: 'UY', label: 'Uruguay' },
      { codeType: AREA_TYPES.COUNTRY, code: 'VE', label: 'Venezuela' }
    ]
  }
];

export const getGeolocation = ({ code, codeType }) => {
  const returnValue = {
    codeType: AREA_TYPES.COUNTRY,
    countryCode: null,
    countryLabel: null,
    continentCode: null,
    continentLabel: null
  };

  if (codeType === AREA_TYPES.DEFAULT) returnValue.codeType = AREA_TYPES.DEFAULT;

  if (codeType === AREA_TYPES.CONTINENT) {
    const continentFound = areas.find(
      (continent) => continent.codeType === AREA_TYPES.CONTINENT && continent.code === code
    );
    returnValue.codeType = AREA_TYPES.CONTINENT;
    returnValue.continentCode = continentFound?.code;
    returnValue.continentLabel = continentFound?.label;
  }
  if (codeType === AREA_TYPES.COUNTRY) {
    const countries = areas.flatMap((continent) =>
      continent.countries.map((country) => ({
        codeType: country.codeType,
        countryCode: country.code,
        countryLabel: country.label,
        continentCode: continent.code,
        continentLabel: continent.label
      }))
    );

    const countryFound = countries.find(
      (country) => country.codeType === AREA_TYPES.COUNTRY && country.countryCode === code
    );
    returnValue.codeType = AREA_TYPES.COUNTRY;
    returnValue.countryCode = countryFound?.countryCode;
    returnValue.countryLabel = countryFound?.countryLabel;
    returnValue.continentCode = countryFound?.continentCode;
    returnValue.continentLabel = countryFound?.continentLabel;
  }
  return returnValue;
};
