import { styled, TextField as MuiTextField, Autocomplete as MuiAutocomplete } from '@mui/material';

export const Autocomplete = styled(MuiAutocomplete)`
  background-color: ${({ theme }) => theme.palette.common.white};
  .MuiInputBase-root {
    gap: 4px;
  }
  ,
  .MuiAutocomplete-input {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  ,
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  ,
  .mzccpicon-root + *: {
    margin-left: 0;
  }
`;

export const TextField = styled(MuiTextField)`
  width: 100%;
  .MuiInputBase-root {
    width: 100%;
    flex-wrap: wrap;
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.primary.main};
        border-width: 1px;
      }
    }
    &.Mui-focused,
    &:focus {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.primary.main};
        border-width: 1px;
      }
    }
    input {
      box-sizing: border-box;
      border:none;
      width: auto;
      height: 20px;
    }
  },
`;
