import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container, Documents } from '@/components/layouts/layout.styled';
import styleTheme from '@/styles/theme';
import MyInfo from '@/contexts/MyInfo';
import Info from '@/contexts/Info';
import MyEventObserver from '@/contexts/MyEventObserver';
import Footer from '@/components/layouts/footer/Footer';
import { styled } from '@mui/system';

export const MonitoringContent = styled('div')(() => ({
  flex: 1,
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `nowrap`,
  justifyContent: `flex-start`,
  alignItems: `stretch`,
  boxSizing: `border-box`,
  backgroundColor: '#f3f3f9',
  height: `100%`,
  minWidth: 0,
  overflow: `auto`
}));

const LayoutForMonitoring = ({ children }) => (
  <MyInfo.Provider>
    <Info.Provider>
      <MyEventObserver.Provider>
        <DndProvider backend={HTML5Backend}>
          <Documents theme={styleTheme}>
            <Container>
              <MonitoringContent>{children}</MonitoringContent>
              <Footer />
            </Container>
          </Documents>
        </DndProvider>
      </MyEventObserver.Provider>
    </Info.Provider>
  </MyInfo.Provider>
);

export default LayoutForMonitoring;
