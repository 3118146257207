import React from 'react';
import { INFORMATION_CONTENT_WIDTH } from '@/components/layouts/informationPanel/styles/informationPanel.styled';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PeriodicMetricsInfo = () => {
  const { t } = useTranslation('cdnMetrics');
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '8px',
        width: `${INFORMATION_CONTENT_WIDTH}px`
      }}
    >
      <Typography>{t(`periodicMetrics.info.header`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.outline`)}</Typography>
      <Typography>{t(`periodicMetrics.info.outline`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.usage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        <ListItem sx={{ display: 'list-item', padding: '2px' }}>
          <Typography>{t(`periodicMetrics.info.usage1`)}</Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', padding: '2px' }}>
          <Typography>{t(`periodicMetrics.info.usage2`)}</Typography>
        </ListItem>
      </List>

      <Typography variant={'h6'}>{t(`property`)}</Typography>
      <Typography>{t(`periodicMetrics.info.property`)}</Typography>
    </Grid>
  );
};
export default PeriodicMetricsInfo;
