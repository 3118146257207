const development = {
  domain: 'localhost',
  startApplicationUrl: null,
  cpd: {
    configuration: 'https://78p0fe0fm5.execute-api.ap-northeast-2.amazonaws.com/idp',
    apiV3: 'https://js4p516nlb.execute-api.ap-northeast-2.amazonaws.com/idp/v3',
    apiV4: 'https://js4p516nlb.execute-api.ap-northeast-2.amazonaws.com/idp/v4',
    channelV1: 'https://channel.idp.cloudplex.me/v1',
    adminV1: 'https://liufq40j5e.execute-api.ap-northeast-2.amazonaws.com/idp/v1',
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: '4VyxQP5cIj0sTmYEXdurxBbNWQ29fu',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default development;
