import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForListMySpaces = () => [`/my-spaces`];

export const useQueryToListMySpaces = (
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListMySpaces(),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/users/me/spaces`,
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
