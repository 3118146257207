import { Drawer as MuiDrawer, styled } from '@mui/material';

export const INFORMATION_PANEL_WIDTH = 448;
export const INFORMATION_CONTENT_WIDTH = 408;

const openedMixin = (theme) => ({
  width: INFORMATION_PANEL_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `48px`,
  overflowX: `hidden`
});

export const DrawerHeader = styled('div')(() => ({
  padding: `12px 8px 12px 20px`
}));

export const DrawerBody = styled('div')(() => ({
  flex: 1,
  position: 'relative',
  overflow: `hidden auto`,
  padding: `20px`
}));

export const ClosedDrawerBody = styled('div')(() => ({
  display: 'flex',
  paddingTop: `8px`,
  alignItems: 'center',
  justifyContent: 'center'
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: INFORMATION_PANEL_WIDTH,
  flexShrink: 0,
  boxSizing: 'border-box',
  '& .MuiPaper-root ': {
    zIndex: theme.zIndex.appBar - 1,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRight: `1px solid ${theme.palette.divider}`
  },
  '.MuiDivider-root': {
    borderColor: theme.palette.grey[200],
    backgroundColor: `transparent`
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));
