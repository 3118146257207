import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const toastTypesDic = {
  success: toast.TYPE.SUCCESS,
  error: toast.TYPE.ERROR,
  warn: toast.TYPE.WARNING,
  info: toast.TYPE.INFO
};

/**
 * exmaple)
 * const {toast} = useToast();
 * toast('some message', { type: toastTypesDic.success, positon: toastPositionsDic.topRight })
 */
export const useToast = () => {
  const [maxCount, setMaxCount] = useState(3); // toast 최대수량
  const count = useRef(0);

  const _toast = useCallback(
    (content, option = {}) => {
      if (count.current < maxCount) {
        count.current += 1;
        option.onClose = () => {};
        return toast(content, {
          ...option,
          onClose: () => {
            if (option?.onClose != null) option.onClose();
            count.current -= 1;
          }
        });
      }
      return null;
    },
    [toast, count, maxCount, setMaxCount]
  );

  return { toast: _toast, setMaxCount, toastInstance: toast };
};
