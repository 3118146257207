import React from 'react';
import { styled, Grid as PdcGrid, Grid } from '@mzc-pdc/ui';

const StyledOverview = styled(PdcGrid)(({ theme }) => ({
  '.MuiListItem-root': {
    marginBottom: `8px`,
    padding: `10px`
  },
  '.MuiListItemText-root ': {
    margin: `0`,
    height: `auto`,
    width: `100%`,
    wordBreak: `break-all`,
    '.MuiListItemText-primary': {
      fontSize: `12px`,
      color: theme.palette.greySecondary[600],
      marginBottom: `8px`
    },
    '.MuiListItemText-secondary': {
      fontSize: `14px`,
      color: theme.palette.greySecondary.main
    }
  }
}));

export const Overview = ({ children }: { children: React.ReactNode }) => (
  <StyledOverview>
    <Grid container flexWrap={`nowrap`} sx={{ p: `24px 4px` }}>
      {children}
    </Grid>
  </StyledOverview>
);

