import { styled, List as MuiList, ListItemText as MuiListItemText } from '@mui/material';

export const List = styled(MuiList)(({ theme }) => ({}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  display: `flex`,
  marginTop: `8px`,
  marginBottom: `8px`,
  alignItems: `center`,
  '.MuiListItemText-primary': {
    minWidth: `30%`,
    fontSize: `14px`,
    color: theme.palette.text.primary,
    '&:before': {
      content: "' '",
      width: `4px`,
      height: `4px`,
      borderRadius: `4px`,
      backgroundColor: theme.palette.grayscaleSecondary.light,
      marginRight: `8px`,
      display: `inline-block`,
      verticalAlign: `3px`
    }
  },
  '.MuiListItemText-secondary': {
    fontSize: `14px`,
    color: theme.palette.text.primary
  }
}));
