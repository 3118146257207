import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UPLOAD_STATUS } from '@/utils/upload';

const StatusBadge = ({ subject, status }) => {
  const { t } = useTranslation('jobs');
  const [component, setComponent] = useState({
    badgeClass: '',
    spriteClass: '',
    statusLabel: ''
  });

  useEffect(() => {
    switch (status) {
      case UPLOAD_STATUS.COMPLETED:
        setComponent({
          badgeClass: 'status-text status-text-complete',
          spriteClass: 'sprite sprite-active',
          statusLabel: t('completed')
        });
        break;
      case UPLOAD_STATUS.ERROR:
        setComponent({
          badgeClass: 'status-text status-text-error',
          spriteClass: 'sprite sprite-error',
          statusLabel: t('error')
        });
        break;
      case UPLOAD_STATUS.PREPARED:
        setComponent({
          badgeClass: 'status-text status-text-submitted',
          spriteClass: 'sprite sprite-progressing',
          statusLabel: t('prepared')
        });
        break;
      default:
        setComponent({
          badgeClass: '',
          spriteClass: '',
          statusLabel: 'unknown'
        });
        break;
    }
  }, [status]);

  return (
    <React.Fragment>
      {subject ? (
        <div className={`${component.badgeClass}`}>
          <i className={`${component.spriteClass}`}></i>
          <span className={'text text-default text-initial'}>{subject}</span>
        </div>
      ) : (
        <div className={`${component.badgeClass}`}>
          <i className={`${component.spriteClass}`}></i>
          <span className={'text'}>{component.statusLabel}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default StatusBadge;
