import React, { createContext, useMemo } from 'react';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import { useQueryToGetMyInfoInProject } from '@/hooks/queries/useQueryToGetMyInfoInProject';

const MyInfoContext = createContext({
  permissions: []
});

const MyInfoContextProvider = (props) => {
  const { spaceId, projectId } = useDeliveryLocation();
  const { data: dataToGetMyInfoInProject } = useQueryToGetMyInfoInProject({ spaceId, projectId });

  const permissions = useMemo(
    () => dataToGetMyInfoInProject?.permissions || [],
    [dataToGetMyInfoInProject]
  );
  return <MyInfoContext.Provider {...props} value={{ permissions }} />;
};

const MyInfo = {
  context: MyInfoContext,
  Provider: MyInfoContextProvider
};

export default MyInfo;
