import { useInfiniteQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const useInfiniteQueryToListMyProjects = (
  { spaceId },
  size = 50,
  options,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useInfiniteQuery(
    [`/spaces/${spaceId}/projects`, size],
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: '/users/me/projects',
          headers: {
            'cloudplex-space-id': spaceId
          },
          params: {
            size: size,
            cursor: context.pageParam
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    {
      ...options,
      getNextPageParam: ({ nextCursor }) => nextCursor ?? null
    }
  );
