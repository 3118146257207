import React, { ReactNode } from 'react';
import { Grid, Typography } from '@mzc-pdc/ui';

type PropertyContainerProps = {
  header?: ReactNode;
  children: ReactNode;
  fullWidth?: boolean;
  isRequired?: boolean;
  isBaseline?: boolean;
};

export const PropertyContainer = ({
  header,
  children,
  isRequired,
  isBaseline
}: PropertyContainerProps) => (
  <Grid container flexWrap={'nowrap'} alignItems={isBaseline ? 'baseline' : 'center'}>
    {header && (
      <Grid item xs={3} alignItems={'center'} gap={0.5}>
        {header}
        {isRequired && (
          <Typography variant={'body1_500'} color={'error'}>
            *
          </Typography>
        )}
      </Grid>
    )}
    <Grid item xs={header ? 9 : 6}>
      {children}
    </Grid>
  </Grid>
);
