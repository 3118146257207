import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForListMyPurgeJobs = ({ spaceId }, page, size) => [
  `/spaces/${spaceId}/my-purge-jobs`,
  page,
  size
];
export const useQueryToListMyPurgeJobs = (
  { spaceId },
  page,
  size,
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForListMyPurgeJobs({ spaceId }, page, size),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/users/me/purge-jobs`,
          headers: {
            'cloudplex-space-id': spaceId
          },
          params: {
            page: page,
            size: size
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
