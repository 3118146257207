import React from 'react';
import { Skeleton as PdcSkeleton } from '@mzc-pdc/ui';

export const Skeleton = (props: {
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
  animation?: 'pulse' | 'wave';
  children?: React.ReactNode;
}) => (
  <PdcSkeleton
    width={'100%'}
    height={'100%'}
    variant={props.variant || 'text'}
    animation={props.animation || 'pulse'}
    sx={{ borderRadius: '4px' }}
  >
  </PdcSkeleton>
);
