import React from 'react';
import theme from '@/styles/theme';
import { Divider, ListItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { List, ListItemText } from '@/components/myJob/tabs/styles/tabs.styled';
import TaskStatus from '@/components/common/statuses/TaskStatus';
import CdnIconName from '@/components/common/CdnIconName';
import { ValidConditionContainer } from '@packages/cp-ui';
import JsonViewer from '@/components/manage/job/common/JsonViewer';

// Job Task V4
const JobTask = ({ task, title }) => {
  const { t } = useTranslation();
  return (
    <ValidConditionContainer
      condition={task != null}
      fallback={
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            textAlign: `center`,
            m: 2,
            py: 6,
            px: 4,
            backgroundColor: theme.palette.secondary[50],
            borderRadius: 2
          }}
        >
          <Typography>
            <Trans i18nKey={'jobs::message.taskDetailInformation'}>
              By selecting the path to detailed information
              <br />
              you can access and review the specific task details
            </Trans>
          </Typography>
        </Box>
      }
    >
      {task != null && (
        <Box sx={{ px: 2, py: 1.5 }}>
          <List>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={
                  <Typography className={'MuiListItemText-primary'}>{t('jobs::label')}</Typography>
                }
                secondary={title}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={<Typography className={'MuiListItemText-primary'}>CDN</Typography>}
                secondary={<CdnIconName isNameVisible service={task.cdn.service} />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                sx={{ my: 0.5 }}
                disableTypography
                primary={
                  <Typography className={'MuiListItemText-primary'}>{t('jobs::status')}</Typography>
                }
                secondary={<TaskStatus status={task.status} />}
              />
            </ListItem>
          </List>
          <Divider sx={{ mb: 3 }} />
          <JsonViewer title={t('jobs::report')} json={task.report} />
        </Box>
      )}
    </ValidConditionContainer>
  );
};

export default JobTask;
