import local from '@/config/local';
import idp from '@/config/idp';
import qa from '@/config/qa';
import production from '@/config/production';
import { default as lodash } from '@/utils/lodash';

const getConfig = () => {
  const env = process.env.REACT_APP_NOT_SECRET_CODE;
  const cond = lodash.cond([
    [lodash.equals('local'), lodash.set('env', lodash, local)],
    [lodash.equals('idp'), lodash.set('env', lodash, idp)],
    [lodash.equals('qa'), lodash.set('env', lodash, qa)],
    [lodash.equals('production'), lodash.set('env', lodash, production)],
    [lodash.T, lodash.identity]
  ]);

  const getEnv = (env) => (lodash.isEmpty(env) ? env : lodash.pipe(lodash.trim, cond)(env));

  return getEnv(env);
};

export default getConfig();
