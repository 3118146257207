import { styled } from '@mui/material/styles';
import { Grid as MuiGrid } from '@mui/material';


export const StyledOverview = styled(MuiGrid)(({ theme }) => ({
  '.MuiListItem-root': {
    marginBottom: `8px`,
    padding: `10px`
  },
  '.MuiListItemText-root ': {
    margin: `0`,
    height: `auto`,
    width: `100%`,
    wordBreak: `break-all`,
    '.MuiListItemText-primary': {
      fontSize: `12px`,
      color: theme.palette.secondary[600],
      marginBottom: `8px`
    },
    '.MuiListItemText-secondary': {
      fontSize: `14px`,
      color: theme.palette.secondary.main
    }
  }
}));
