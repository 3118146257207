import { styled, Chip as MuiChip, Grid as MuiGrid } from '@mui/material';

export const StyledStatus = styled(MuiChip)(({ theme, status }) => ({
  minWidth: `96px`,
  height: `22px`,
  borderRadius: `2px`,
  textAlign: `center`,
  fontWeight: `bold`,
  marginRight: `10px`,
  '.MzcCpIcon-root + .MuiChip-label': {
    marginLeft: 0
  }
}));

export const StyledStatusText = styled(MuiGrid)(({ theme, status }) => ({
  display: `flex`,
  alignItems: `center`,
  flexWrap: `nowrap`,
  gap: `6px`,
  whiteSpace: `nowrap`
}));
