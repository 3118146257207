import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Toolbar } from '@mui/material';
import { Container, Content, Documents } from '@/components/layouts/layout.styled';
import styleTheme from '@/styles/theme';
import MyInfo from '@/contexts/MyInfo';
import Info from '@/contexts/Info';
import MyEventObserver from '@/contexts/MyEventObserver';

import Header from '@/components/layouts/header/Header';
import Footer from '@/components/layouts/footer/Footer';
import Navigator from '@/components/navigation/Navigator';
import JobListContainer from '@/components/myJob/JobListContainer';
import InformationPanel from '@/components/layouts/informationPanel/InformationPanel';

const Layout = ({ children }) => {
  const [hasNewEvent, setHasNewEvent] = useState(false);
  const [isJobPanelOpen, setJobPanelOpen] = useState(false);
  return (
    <MyInfo.Provider>
      <Info.Provider>
        <MyEventObserver.Provider>
          <DndProvider backend={HTML5Backend}>
            <Documents theme={styleTheme}>
              <Header
                openJobPanel={() => setJobPanelOpen(true)}
                hasNewEvent={hasNewEvent}
                newEventOn={() => setHasNewEvent(true)}
              />
              <Navigator />
              <Container>
                <Toolbar />
                <Content>{children}</Content>
                <JobListContainer
                  isJobPanelOpen={isJobPanelOpen}
                  closeJobPanel={() => {
                    setJobPanelOpen(false);
                    setHasNewEvent(false);
                  }}
                />
                <Footer />
              </Container>
              <InformationPanel />
            </Documents>
          </DndProvider>
        </MyEventObserver.Provider>
      </Info.Provider>
    </MyInfo.Provider>
  );
};

export default Layout;
