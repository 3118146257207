import { handleActions, createAction } from 'redux-actions';

const RIGHT_SIDE_BAR_SET_INACTIVE = 'RIGHT_SIDE_BAR_SET_INACTIVE';
const RIGHT_SIDE_BAR_TOGGLE_ACTIVE = 'RIGHT_SIDE_BAR_TOGGLE_ACTIVE';
const RESET = 'rightSideBar/RESET';
const FAILURE = 'rightSideBar/FAILURE';
const PENDING = 'rightSideBar/PENDING';
const SUCCESS = 'rightSideBar/SUCCESS';
const SET_HAS_ACTIVE_JOB = 'rightSideBar/SET_HAS_ACTIVE_JOB';

const initialState = {
  type: '',
  active: false,

  status: 'idle',
  error: {},
  hasActiveJob: false,

  data: []
};

export default handleActions(
  {
    [RIGHT_SIDE_BAR_SET_INACTIVE]: (state) => {
      if (state.active) {
        return {
          ...state,
          active: false
        };
      }

      return state;
    },
    [RIGHT_SIDE_BAR_TOGGLE_ACTIVE]: (state, action) => ({
      ...state,
      type: action.payload,
      active: !state.active
    }),
    [RESET]: (state) => ({
      ...state,
      status: 'idle',
      error: {},
      data: []
    }),

    [PENDING]: (state) => ({
      ...state,
      status: 'pending'
    }),

    [FAILURE]: (state, { payload }) => ({
      ...state,
      status: 'idle',
      error: payload || {}
    }),

    [SUCCESS]: (state, { payload }) => ({
      ...state,
      status: 'idle',
      error: {},
      data: _.orderBy(['createdDate'], ['desc'], payload || [])
    }),

    [SET_HAS_ACTIVE_JOB]: (state, { payload }) => ({
      ...state,
      hasActiveJob: payload
    })
  },
  initialState
);

export const pending = createAction(PENDING);
export const setHasActiveJob = createAction(SET_HAS_ACTIVE_JOB);
