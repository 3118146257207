import React from 'react';
import { INFORMATION_CONTENT_WIDTH } from '@/components/layouts/informationPanel/styles/informationPanel.styled';
import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OriginStorageInfo = () => {
  const { t } = useTranslation('originStorage');
  const usageList = ['usage1', 'usage2', 'usage3', 'usage4', 'usage5', 'usage6'];
  const protocolReference = 'https://datatracker.ietf.org/doc/html/rfc3986';
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '8px',
        width: `${INFORMATION_CONTENT_WIDTH}px`
      }}
    >
      <Typography>{t(`info.header`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.outline`)}</Typography>
      <Typography>{t(`info.outline`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.usage`)}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 2 }}>
        {usageList.map((usage) => (
          <ListItem key={usage} sx={{ display: 'list-item', padding: '2px' }}>
            <Typography>{t(`info.${usage}`)}</Typography>
          </ListItem>
        ))}
      </List>
      <Typography>{t(`info.endpointSetting`)}</Typography>

      <Typography variant={'h6'}>{t(`common::label.result`)}</Typography>
      <Typography>{t(`info.result`)}</Typography>

      <Typography variant={'h6'}>{t(`info.protocolHeader`)}</Typography>
      <Typography>{t(`info.protocolContent`)}</Typography>
      <Link href={protocolReference} target={'_blank'}>
        {t(`info.protocolReference`)}
      </Link>
    </Grid>
  );
};
export default OriginStorageInfo;
