import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Select, { components } from 'react-select';
import uniqid from 'uniqid';

const MenuList = (props) => {
  const { listNextOptions, hasNextOptions, isNextOptionsFetching } = props.selectProps.nextOptions;
  const [lastElementInScrollRef, inViewLastElementInScrollRef] = useInView();

  useEffect(() => {
    if (isNextOptionsFetching === false && !!hasNextOptions && inViewLastElementInScrollRef)
      listNextOptions();
  }, [isNextOptionsFetching, inViewLastElementInScrollRef]);

  return (
    <components.MenuList {...props}>
      <div>{props.children}</div>
      {!!hasNextOptions && (
        <div className='loading' ref={lastElementInScrollRef}>
          <i className='loading-content'></i>
        </div>
      )}
    </components.MenuList>
  );
};

const WithSelect = (props) => {
  const {
    isMulti,
    defaultValue,
    options,
    placeholder,
    disabled,
    // eslint-disable-next-line no-unused-vars
    hidden,
    // eslint-disable-next-line no-unused-vars
    size,
    // eslint-disable-next-line no-unused-vars
    isError,
    // eslint-disable-next-line no-unused-vars
    isTextWrap,
    hideSelectedOptions,
    closeMenuOnSelect,
    onChange,
    onFocus,
    onBlur,
    // eslint-disable-next-line no-unused-vars
    noOptionsMessage,
    components,
    nextOptions,
    ...otherProps
  } = props;

  const [id] = useState(uniqid());

  const [defaultValue_, setDefaultValue_] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (isMulti) {
      setDefaultValue_(defaultValue);
      setValue(defaultValue);
    } else if (options) {
      setDefaultValue_(options.filter((v) => v.value === defaultValue));
      setValue(options.filter((v) => v.value === defaultValue));
    }
  }, [isMulti, defaultValue, options]);

  return (
    <Select
      id={id}
      placeholder={placeholder || 'Select..'}
      className={'select2-container'}
      classNamePrefix={'select2-selection'}
      components={{ ...components, MenuList }}
      nextOptions={nextOptions}
      defaultValue={defaultValue_}
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      onFocus={(e) => {
        onFocus && onFocus(e);
        e.target.closest('.select2-container').classList.add('select2-focus');
      }}
      onBlur={(e) => {
        if (onBlur) onBlur(e);
        e.target.closest('.select2-container').classList.remove('select2-focus');
      }}
      hideSelectedOptions={hideSelectedOptions !== undefined ? hideSelectedOptions : false}
      closeMenuOnSelect={closeMenuOnSelect !== undefined ? closeMenuOnSelect : true}
      menuPlacement={'auto'}
      {...otherProps}
    />
  );
};

export default WithSelect;
