import React from 'react';
import {Content, Documents} from "@/components/layouts/layout.styled";
import theme from "@/styles/theme";

const LayoutForError = ({children}) => (
  <Documents theme={theme} style={{background: theme.palette.background.default, flexDirection: `column`}}>
    <Content>
      {children}
    </Content>
  </Documents>
);

export default LayoutForError;
