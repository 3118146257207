import React, { useCallback, useState } from 'react';
import PurgeJobDetailModal from '@/components/myJob/modals/PurgeJobDetailModal';

const usePurgeJobDetailModal = ({ spaceId }) => {
  const [isOpen, setOpen] = useState(false);
  const [job, setJob] = useState({});

  return {
    show: useCallback(({ job }) => {
      setJob({ ...job });
      setOpen(true);
    }, []),
    Modal: useCallback(
      () =>
        isOpen ? (
          <PurgeJobDetailModal
            spaceId={spaceId}
            job={job}
            onClose={() => setOpen(false)}
          />
        ) : null,
      [isOpen, spaceId, job]
    )
  };
};

export default usePurgeJobDetailModal;
