import React from 'react';
import { Divider, FormControl, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@packages/legacy-ui';
import { IconArrowBack, IconArrowForward, IconRefresh } from '@mzc-cloudplex/icons';
import theme from '@/styles/theme';
import Select from '@mui/material/Select';

const ListTool = ({ totalCount, page, pageSize, onChange, onRefresh, divider, tool }) => {
  const { t } = useTranslation();
  const startItem = (page - 1) * pageSize + 1;
  const endItem = page * pageSize;
  const overflow = startItem > totalCount;
  const getPageInfo = () => {
    if (totalCount === 0) return `${1} - ${10} ${t('of')} ${0}`;

    if (overflow) return `${startItem} - ${endItem} ${t('of')} ${totalCount ?? '-'}`;

    if (totalCount < endItem) return `${startItem} - ${totalCount} ${t('of')} ${totalCount ?? '-'}`;
    else return `${startItem} - ${endItem} ${t('of')} ${totalCount ?? '-'}`;
  };

  return (
    <div style={{ width: '100%' }}>
      {divider !== false && (
        <Divider
          orientation={'horizontal'}
          sx={{ width: `100%`, borderColor: `${theme.palette.secondary[800]}` }}
        />
      )}
      <Grid
        container
        sx={{ paddingY: '12px', paddingX: `0` }}
        alignItems={`center`}
        justifyContent={`space-between`}
      >
        <Grid item display={'flex'} gap={1}>
          {tool}
        </Grid>
        <Grid item>
          <Grid container gap={0.5} alignItems={`center`}>
            {onRefresh && (
              <Tooltip title={t('label.refresh')}>
                <span>
                  <IconButton circled size={'medium'} onClick={onRefresh}>
                    <IconRefresh size={16} htmlColor={theme.palette.secondary.main} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {getPageInfo()}
            <Tooltip title={t('label.previous')}>
              <span>
                <IconButton
                  circled
                  size={'medium'}
                  disabled={page === 1}
                  onClick={() => onChange?.(page - 1, pageSize)}
                >
                  <IconArrowBack size={16} htmlColor={theme.palette.secondary.main} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('label.next')}>
              <span>
                <IconButton
                  circled
                  size={'medium'}
                  disabled={totalCount <= page * pageSize}
                  onClick={() => onChange?.(page + 1, pageSize)}
                >
                  <IconArrowForward size={16} htmlColor={theme.palette.secondary.main} />
                </IconButton>
              </span>
            </Tooltip>
            <FormControl>
              <Select
                size={'small'}
                value={pageSize}
                onChange={(e) => onChange?.(1, e.target.value)}
              >
                <MenuItem value={10}>{t('label.listView', { viewValue: 10 })}</MenuItem>
                <MenuItem value={20}>{t('label.listView', { viewValue: 20 })}</MenuItem>
                <MenuItem value={30}>{t('label.listView', { viewValue: 30 })}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ListTool;
