import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@packages/legacy-ui';
import { Grid } from '@mui/material';

const ModalContainer = ({
  title,
  modalType,
  panel,
  panelOpen,
  panelExpand,
  width,
  maxWidth,
  cancelButtonTitle,
  confirmButtonTitle,
  submitButtonTitle,
  submitButtonColor,
  submitButtonVariant,
  isLoading,
  submitDisabled = false,
  onClickCancel,
  onClickSubmit,
  onClickConfirm,
  onClose,
  buttonWrapperCustom,
  children,
  headerClose
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      type={modalType}
      panel={panel}
      panelOpen={panelOpen}
      panelExpand={panelExpand}
      onClose={() => onClose?.()}
      open={true}
      width={width}
      maxWidth={maxWidth}
      modalTitle={title}
      headerClose={headerClose}
      onClickClose={onClose}
      actions={
        (onClickCancel != null || onClickSubmit != null || onClickConfirm != null) && (
          <Grid container justifyContent={'space-between'}>
            <Grid item>{buttonWrapperCustom}</Grid>
            {(onClickCancel != null || onClickSubmit != null || onClickConfirm != null) && (
              <Grid item xs sx={{ textAlign: `right` }}>
                {onClickCancel != null && (
                  <Button
                    variant={'text'}
                    color={'inherit'}
                    size={'medium'}
                    onClick={onClickCancel}
                  >
                    {cancelButtonTitle || t(`label.cancel`)}
                  </Button>
                )}
                {onClickConfirm != null && (
                  <Button variant={'contained'} color={'primary'} size={'medium'} onClick={onClose}>
                    {confirmButtonTitle || t(`label.confirm`)}
                  </Button>
                )}
                {onClickSubmit != null && (
                  <Button
                    variant={submitButtonVariant ? submitButtonVariant : 'contained'}
                    color={submitButtonColor}
                    size={'medium'}
                    loading={isLoading}
                    disabled={submitDisabled}
                    onClick={onClickSubmit}
                  >
                    <strong>{submitButtonTitle}</strong>
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        )
      }
    >
      {children}
    </Dialog>
  );
};

ModalContainer.propTypes = {};

export default ModalContainer;
