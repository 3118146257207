import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetProject = ({ spaceId, projectId }) => [
  `/spaces/${spaceId}/projects/${projectId}`
];
export const useQueryToGetProject = (
  { spaceId, projectId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetProject({ spaceId, projectId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/projects/me`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    {
      staleTime: 1000 * 5, // 5초 캐쉬 사용
      cacheTime: 1000 * 5,
      ...options
    }
  );
