function getByteLength(string, bytes, index, character) {
  if (!string) return 0;
  for (
    bytes = index = 0;
    (character = string.charCodeAt(index++));
    bytes += character >> 11 ? 3 : character >> 7 ? 2 : 1
  );
  return bytes;
}

export default getByteLength;
