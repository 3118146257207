import React, { ReactNode } from 'react';
import { Link as RouteLink } from 'react-router-dom';

export interface LinkProps {
  to?: string;
  target?: '_self' | '_blank';
  children: ReactNode;
}

export const Link = ({ to, target, children }: LinkProps) =>
  to ? (
    <RouteLink to={to} target={target ?? '_self'}>
      {children}
    </RouteLink>
  ) : (
    <>{children}</>
  );
