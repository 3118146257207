import { Box, Typography } from '@mzc-pdc/ui';
import React from 'react';
import { ReactNode } from 'react';

interface RequiredLabelProps {
  children: ReactNode;
}

export const RequiredLabel = (props: RequiredLabelProps) => (
  <Box display={'inline-flex'} gap={0.5} alignItems={'center'}>
    <Typography>{props.children}</Typography>
    <Typography color={'error'}>*</Typography>
  </Box>
);
