import React from 'react';
import { IconErrorCircle, IconWarning, IconCheck } from '@mzc-cloudplex/icons';
import { COMPARE_JOB_RESULT } from '@/services/job/compareJobService';
import { styled, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledChip = styled(Chip)`
  min-width: 96px;
  height: 22px;
  border-radius: 2px;
  text-align: center;
  font-weight: bold;
  margin-right: 10px;
`;

const CompareJobResultLabel = ({ status }) => {
  const { t } = useTranslation('jobs');
  if (status === COMPARE_JOB_RESULT.PREPARED) {
    return <StyledChip label={t('prepared')} />;
  } else if (status === COMPARE_JOB_RESULT.ALL_IDENTICAL) {
    return (
      <StyledChip
        label={t('completed')}
        color={'success'}
        icon={<IconCheck size={12} htmlColor={'#fff'} />}
      />
    );
  } else if (status === COMPARE_JOB_RESULT.INCLUDE_DIFFERENT) {
    return (
      <>
        <StyledChip
          label={t('completed')}
          color={'success'}
          icon={<IconCheck size={12} htmlColor={'#fff'} />}
        />
        <IconErrorCircle size={16} color={'warning'} />
      </>
    );
  } else if (status === COMPARE_JOB_RESULT.ALL_FAILED) {
    return (
      <StyledChip
        label={t('completed')}
        color={'error'}
        icon={<IconWarning size={12} htmlColor={'#fff'} />}
      />
    );
  } else if (status === COMPARE_JOB_RESULT.IN_PROGRESS) {
    return <StyledChip label={t('inProgress')} color={'primary'} />;
  } else if (status === COMPARE_JOB_RESULT.INCLUDE_FAILED) {
    return (
      <>
        <StyledChip
          label={t('completed')}
          color={'success'}
          icon={<IconCheck size={12} htmlColor={'#fff'} />}
        />
        <IconErrorCircle size={16} color={'error'} />
      </>
    );
  }
};

export default CompareJobResultLabel;
